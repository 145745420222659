/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_MAPSTYLE } from '../../../../config'
import { IBassMapState } from './basemapInterface'

const stateKey = 'developerMap'

const initialState: IBassMapState = {
  mapStyle: DEFAULT_MAPSTYLE,
  defaultMapStyle: DEFAULT_MAPSTYLE,
}

const basemap = createSlice({
  name: 'basemap',
  initialState,
  reducers: {
    changeMapStyle: (state, action) => {
      const { payload } = action

      if (payload !== 'popety/cl9mkt6w3002714rsqw7qsz7g') {
        state.defaultMapStyle = payload
        state.mapStyle = payload
      }

      localStorage.setItem(stateKey, JSON.stringify(state))
      state.mapStyle = payload
    },
  },
})

export const basemapReducer = {
  basemap: basemap.reducer,
}

export const { changeMapStyle } = basemap.actions
