/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import { useI18n } from '@popety_io/popety-io-lib'
import { FC, useEffect, useState } from 'react'

import {
  IHeatingAndHotWaterEditedValues,
  IHeatingAndHotWaterValues,
} from '../redux/energy/energyType'
import {
  AdditionHeatingKeys,
  AdditionHotWaterKeys,
  HeatAndWaterKeyTypes,
  HeatingKeys,
  HotWaterKeys,
  getHWDefaultStates,
  getHWEditedStates,
  getHWEditedValues,
} from '../utils/heatingAndHotWater'
import { HeatingAndHotWater } from '../../../components/HeatingAndHotWater'
import CollapseList from '../../LandDetail/Summary/CollapseList'

interface IProp {
  building: any
  heatingAndHotWaterEdited?: any
  onSaveChange: (values: IHeatingAndHotWaterEditedValues) => void
  isLoading: boolean
  type?: string
  toggleDisplay?: (name: string) => (val: boolean) => void
  defaultShow?: boolean
}

const HeatingAndHotWaterSection: FC<IProp> = ({
  building,
  heatingAndHotWaterEdited,
  onSaveChange,
  isLoading,
  type,
  toggleDisplay,
  defaultShow = true,
}) => {
  const { t } = useI18n()
  const [editHeating, setEditHeating] = useState(false)
  const [show, setShow] = useState(defaultShow)
  const data = heatingAndHotWaterEdited || building
  const estimateIsLoading = false

  useEffect(() => {
    setShow(defaultShow)
  }, [defaultShow])

  const mapStates = () => {
    return {
      heater_generator: data?.heater_generator,
      power_source_heater: data?.power_source_heater,
      heater_datasource: data?.heater_datasource,
      last_update_timestamp_heater:
        data?.last_update_timestamp_heater?.split('T')[0],
      heater_water_generator: data?.heater_water_generator,
      power_source_water_heater: data?.power_source_water_heater,
      water_heater_datasource: data?.water_heater_datasource,
      last_update_timestamp_water_heater:
        data?.last_update_timestamp_water_heater?.split('T')[0],
      additional_heater_generator: data?.additional_heater_generator,
      power_source_additional_heater: data?.power_source_additional_heater,
      additional_heater_datasource: data?.additional_heater_datasource,
      last_update_timestamp_additional_heater:
        data?.last_update_timestamp_additional_heater?.split('T')[0],

      additional_heater_water_generator:
        data?.additional_heater_water_generator,
      power_source_additional_water_heater:
        data?.power_source_additional_water_heater,
      additional_water_heater_datasource:
        data?.additional_water_heater_datasource,
      last_update_timestamp_additional_water_heater:
        data?.last_update_timestamp_additional_water_heater?.split('T')[0],
    }
  }

  const [values, setValues] = useState<IHeatingAndHotWaterValues>(mapStates())

  const handleEditHeating = () => {
    if (editHeating) {
      setValues(mapStates())
    }
    setEditHeating(!editHeating)
  }

  useEffect(() => {
    setValues(mapStates())
  }, [building])

  const updateHeating = (name: string, value: string) => {
    setValues((pre: IHeatingAndHotWaterValues) => ({
      ...(pre || {}),
      [HeatingKeys[name as keyof HeatAndWaterKeyTypes]]: value || '',
    }))
  }

  const updateHotWater = (name: string, value: string) => {
    setValues((pre: IHeatingAndHotWaterValues) => ({
      ...(pre || {}),
      [HotWaterKeys[name as keyof HeatAndWaterKeyTypes]]: value || '',
    }))
  }

  const updateAdditionHeating = (name: string, value: string) => {
    setValues((pre: IHeatingAndHotWaterValues) => ({
      ...(pre || {}),
      [AdditionHeatingKeys[name as keyof HeatAndWaterKeyTypes]]: value || '',
    }))
  }

  const updateAdditionHotWater = (name: string, value: string) => {
    setValues((pre: IHeatingAndHotWaterValues) => ({
      ...(pre || {}),
      [AdditionHotWaterKeys[name as keyof HeatAndWaterKeyTypes]]: value || '',
    }))
  }

  const onSave = () => {
    if (values) {
      const defaultBuilding = {
        ...building,
        last_update_timestamp_heater:
          building?.last_update_timestamp_heater?.split('T')[0],
        last_update_timestamp_water_heater:
          building?.last_update_timestamp_water_heater?.split('T')[0],
        last_update_timestamp_additional_heater:
          building?.last_update_timestamp_additional_heater?.split('T')[0],
        last_update_timestamp_additional_water_heater:
          building?.last_update_timestamp_additional_water_heater?.split(
            'T',
          )[0],
      }

      onSaveChange(getHWEditedValues(values, defaultBuilding))
    }

    setEditHeating(false)
  }

  const onRetrieveHeating = (name: string) => {
    updateHeating(
      name,
      heatingAndHotWaterEdited[
        `${HeatingKeys[name as keyof HeatAndWaterKeyTypes]}_edited`
      ],
    )
  }
  const onRetrieveHotWater = (name: string) => {
    updateHotWater(
      name,
      heatingAndHotWaterEdited[
        `${HotWaterKeys[name as keyof HeatAndWaterKeyTypes]}_edited`
      ],
    )
  }
  const onRetrieveAdditionHeating = (name: string) => {
    updateHeating(
      name,
      building[
        `${AdditionHeatingKeys[name as keyof HeatAndWaterKeyTypes]}_edited`
      ],
    )
  }
  const onRetrieveAdditionHotWater = (name: string) => {
    updateHeating(
      name,
      building[
        `${AdditionHotWaterKeys[name as keyof HeatAndWaterKeyTypes]}_edited`
      ],
    )
  }

  const onToggle = (checked: boolean) => {
    toggleDisplay?.('heatAndHot')(checked)
    setShow(checked)
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBlock: '16px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          {t('building.energy.Chauffage et eau chaude')}
        </Typography>
        {type === 'estimate' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!editHeating && show && (
              <IconButton
                onClick={handleEditHeating}
                disabled={estimateIsLoading}
                sx={{ fill: '#969696', cursor: 'pointer' }}
              >
                <EditIcon />
              </IconButton>
            )}

            {editHeating && show && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <IconButton
                  onClick={onSave}
                  disabled={isLoading}
                  sx={{ fill: '#969696', cursor: 'pointer', marginRight: 2 }}
                >
                  <SaveIcon />
                </IconButton>

                <IconButton
                  onClick={handleEditHeating}
                  disabled={isLoading}
                  sx={{ fill: '#969696', cursor: 'pointer', marginRight: 2 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}

            {type === 'estimate' && (
              <Tooltip
                title={
                  show
                    ? t('common.disable_button_tooltip')
                    : t('common.activate')
                }
              >
                <Switch
                  checked={show}
                  onChange={(e) => onToggle(e.target.checked)}
                  size="small"
                  disabled={estimateIsLoading}
                />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
      {show && (
        <CollapseList
          sx={{
            backgroundColor: '#F9F9F9',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            padding: '7px 6px',
            gap: '24px',
            '@media (max-width: 991px)': {
              gridTemplateColumns: '1fr',
            },
          }}
          min={2}
          moreKey="common.system.Voir système supplémentaire"
          items={
            [
              <HeatingAndHotWater
                title={t(
                  'building.energy.Système de chauffage le plus puissant',
                )}
                currentValues={getHWDefaultStates(
                  values,
                  HeatingKeys,
                  editHeating,
                )}
                usesEditedValues={getHWEditedStates(
                  heatingAndHotWaterEdited,
                  HeatingKeys,
                )}
                defaultValues={getHWDefaultStates(building, HeatingKeys)}
                isEdit={editHeating}
                onChangeValue={updateHeating}
                onReset={(name) =>
                  updateHeating(
                    name,
                    building[HeatingKeys[name as keyof HeatAndWaterKeyTypes]],
                  )
                }
                onRetrieve={onRetrieveHeating}
                isLoading={isLoading}
              />,
              <HeatingAndHotWater
                title={t(
                  'building.energy.Système pour leau chaude le plus puissant',
                )}
                currentValues={getHWDefaultStates(
                  values,
                  HotWaterKeys,
                  editHeating,
                )}
                usesEditedValues={getHWEditedStates(
                  heatingAndHotWaterEdited,
                  HotWaterKeys,
                )}
                defaultValues={getHWDefaultStates(building, HotWaterKeys)}
                isEdit={editHeating}
                onChangeValue={updateHotWater}
                onReset={(name) =>
                  updateHotWater(
                    name,
                    building[HotWaterKeys[name as keyof HeatAndWaterKeyTypes]],
                  )
                }
                onRetrieve={onRetrieveHotWater}
                isLoading={isLoading}
              />,
              <HeatingAndHotWater
                title={t('building.energy.Système de chauffage supplémentaire')}
                currentValues={getHWDefaultStates(
                  values,
                  AdditionHeatingKeys,
                  editHeating,
                )}
                usesEditedValues={getHWEditedStates(
                  heatingAndHotWaterEdited,
                  AdditionHeatingKeys,
                )}
                defaultValues={getHWDefaultStates(
                  building,
                  AdditionHeatingKeys,
                )}
                isEdit={editHeating}
                onChangeValue={updateAdditionHeating}
                onReset={(name) =>
                  updateAdditionHeating(
                    name,
                    building[
                      AdditionHeatingKeys[name as keyof HeatAndWaterKeyTypes]
                    ],
                  )
                }
                onRetrieve={onRetrieveAdditionHeating}
                isLoading={isLoading}
              />,
              <HeatingAndHotWater
                title={t(
                  'building.energy.Système pour leau chaude supplémentaire',
                )}
                currentValues={getHWDefaultStates(
                  values,
                  AdditionHotWaterKeys,
                  editHeating,
                )}
                usesEditedValues={getHWEditedStates(
                  heatingAndHotWaterEdited,
                  AdditionHotWaterKeys,
                )}
                defaultValues={getHWDefaultStates(
                  building,
                  AdditionHotWaterKeys,
                )}
                isEdit={editHeating}
                onChangeValue={updateAdditionHotWater}
                onReset={(name) =>
                  updateAdditionHotWater(
                    name,
                    building[
                      AdditionHotWaterKeys[name as keyof HeatAndWaterKeyTypes]
                    ],
                  )
                }
                onRetrieve={onRetrieveAdditionHotWater}
                isLoading={isLoading}
              />,
            ] as any
          }
        />

        // </CollapseList>
      )}
    </div>
  )
}

export default HeatingAndHotWaterSection
