/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box'
import {
  PopetyMaps,
  PrintIcon,
  useI18n,
  useRouter,
} from '@popety_io/popety-io-lib'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DEFAULT_MAPSTYLE, MAPBOX_TOKEN, STYLE_PREFIX } from '../../../config'
import { transformTileRequest } from '../../../utils'
import { changeMapStyle, mapStyleSelector } from '../../Estimate/redux'
import { buildOptions } from '../../LandDetail/components/Map/basemap.util'
import GeolocMapControls from '../../LandDetail/components/Map/MapControls/GeolocMapControls'
import {
  getBbox,
  getHistoryBounds,
} from '../../LandDetail/components/Map/utils'
import ControlMapStyleSwitch from '../../LandDetail/Summary/ControlMapStyleSwitch'
import { buildingAnalysisHistorySelector, buildingSelector } from '../redux'
import { MapConfigByPage, MapControllers } from './BuildingDetailsMap.config'
import { useBuildingDetailsMapLayers } from './BuildingDetailsMap.layers'

export type BuildingDetailsMapProps = {
  fullscreen?: boolean
  isPlot?: boolean
}

interface Building {
  archived: boolean
}

const BuildingDetailsMap = ({
  fullscreen,
  isPlot,
}: BuildingDetailsMapProps) => {
  const [zoom, setZoom] = useState(isPlot ? 24 : 18)
  const dispatch = useDispatch()

  const buildingAnalysisHistory = useSelector(buildingAnalysisHistorySelector)
  const mapStyle = useSelector(mapStyleSelector)
  const building = useSelector(buildingSelector)
  const { data } = useBuildingDetailsMapLayers()

  const { t } = useI18n()

  const { query, updateQuery } = useRouter()

  const isArchived = query?.archived === 'true'
  const isActived = query?.active === 'true'

  const history = useMemo(() => {
    if (!isActived && !isArchived) {
      return []
    }

    return buildingAnalysisHistory.filter((building: Building) => {
      return (
        (isActived && !building?.archived) || (isArchived && building?.archived)
      )
    })
  }, [buildingAnalysisHistory, isActived, isArchived])

  const MapConfig = useMemo(
    () => MapConfigByPage[query.subTab || query.tab],
    [query.subTab, query.tab],
  )

  const geoCenter = [
    building?.geo_center?.lon,
    building?.geo_center?.lat,
  ].filter(Boolean)

  const isHomePage = query?.tab === 'home'

  useEffect(() => {
    if (query.zoom) {
      setZoom(Number(query.zoom))
    }
  }, [query.zoom])

  useEffect(() => {
    updateQuery({ zoom })
  }, [])

  const minHeight = fullscreen
    ? 'calc(100vh - 160px)'
    : { xs: '300px', md: '40vh' }

  const bbox = useMemo(() => {
    return isHomePage && history?.length
      ? getHistoryBounds(history)
      : building?.geo_center
        ? getBbox(data, query, geoCenter)
        : undefined
  }, [query, geoCenter, data, history])

  const mapProps: any = {
    zoom,
    bearing: 0,
    bearingSnap: 0,
    dragPan: true,
    dragRotate: true,
    maxZoom: 40,
    // minZoom: 13,
    maxPitch: 60,
    preserveDrawingBuffer: true,
    transformRequest: transformTileRequest as any,
    bbox,
  }

  const options = buildOptions(t, mapStyle)

  const mapConfigStyle = useMemo(() => {
    const style = mapStyle.startsWith(STYLE_PREFIX)
      ? mapStyle.substring(STYLE_PREFIX.length)
      : mapStyle

    return `${STYLE_PREFIX}${style}`
  }, [mapStyle])

  useEffect(() => {
    dispatch(changeMapStyle(MapConfig?.style || DEFAULT_MAPSTYLE))
  }, [MapConfig?.style, query.tab, query.subTab])

  useEffect(() => {
    if (isPlot) {
      dispatch(
        changeMapStyle('mapbox://styles/popety/cl449l1ah000414umwqgcr0pd'),
      )
    }
  }, [])

  return (
    <Box height="100%" minHeight={minHeight}>
      <PopetyMaps
        dimension="3D"
        defaultPitch={40}
        isDimension
        token={MAPBOX_TOKEN}
        bbox={bbox}
        data={data}
        center={building?.geo_center}
        isDragPan
        Hover={MapConfig?.Hover}
        ClickComponent={MapConfig?.Click}
        style={mapConfigStyle}
        {...mapProps}
        sx={{
          minHeight,
          maxHeight: fullscreen
            ? '100%'
            : { xs: 'calc(100% - 106px)', lg: '100%' },
          overflow: 'hidden',
        }}
        zoom={zoom}
        ofsYear={query?.historyLayer ? query.historyLayer : undefined}
      >
        <GeolocMapControls center={geoCenter} zoom={zoom} />
        {MapConfig?.Content && <MapConfig.Content />}
        <MapControllers />
        <PrintIcon name={t('common.myAnalysis.fileName.details')} />
        <ControlMapStyleSwitch options={options.slice(0, 2)} />
      </PopetyMaps>
    </Box>
  )
}

export default BuildingDetailsMap
