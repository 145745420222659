/* eslint-disable prefer-destructuring */
/* eslint-disable max-lines */
import { Map } from '@popety_io/popety-io-lib'
import { isDev } from '../../../../../config'

const mapLoaded = (map: Map) => {
  return new Promise((res: any, _rej) => {
    function cb() {
      if (!map.loaded()) return

      map.off('render', cb)
      res()
    }

    map.on('render', cb)
    cb()
  })
}

const buildShadeMap = ({
  map,
  sunDate,
  customSunlightBuilding,
  buildingDetails,
}: {
  map: Map
  sunDate: Date
  customSunlightBuilding?: any
  buildingDetails?: any
}) => {
  const ShadeMap = (window as any)?.ShadeMap

  return new ShadeMap({
    date: new Date(sunDate),
    apiKey:
      'eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InRwcGlvdHJvd3NraUBzaGFkZW1hcC5hcHAiLCJjcmVhdGVkIjoxNjYyNDkzMDY2Nzk0LCJpYXQiOjE2NjI0OTMwNjZ9.ovCrLTYsdKFTF6TW3DuODxCaAtGQ3qhcmqj3DWcol5g',
    color: '#01112f',
    opacity: 0.7,
    terrainSource: {
      maxZoom: 15,
      tileSize: 256,
      getSourceUrl: ({ x, y, z }: any) =>
        `https://s3.amazonaws.com/elevation-tiles-prod/terrarium/${z}/${x}/${y}.png`,
      getElevation: ({ r, g, b }: any) => r * 256 + g + b / 256 - 32768,
      _overzoom: 20,
    },
    getFeatures: async () => {
      await mapLoaded(map)

      const data = map
        ?.querySourceFeatures('tileSource', { sourceLayer: 'hits' })
        ?.filter((feature: any) => {
          if (!feature.properties) return false

          feature.properties.height =
            feature.properties.height || feature.properties.floor_nb * 3

          return feature.properties.height || feature.properties.render_height
        })

      let buildingData =
        customSunlightBuilding && customSunlightBuilding.length > 0
          ? [
              ...data,
              ...customSunlightBuilding.map(
                (x: any) => x?.data?.data?.features?.[0],
              ),
            ]
          : data

      if (buildingDetails) {
        buildingData = [
          ...buildingData,
          ...buildingDetails.map((x: any) => x?.data),
        ]
      }

      return buildingData || []
    },
    debug: (msg: string) => {
      if (isDev) console.debug(new Date().toISOString(), msg)
    },
  }).addTo(map)
}

const getLongitudeTimeZone = (lng: any) => {
  return `Etc/GMT+${-Math.ceil((lng / 7.5 - 1) / 2)}`.replace('+-', '-')
}

const getTimeZone = (map: any) => {
  const { lng } = map.getCenter()

  return getLongitudeTimeZone(lng)
}

const UTCOffsetByTimeZone = (date = new Date(), timeZone: any = 'UTC') => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }))

  return utcDate.getTime() - tzDate.getTime()
}

const generateProfile = async ({
  map,
  shadeMapRef,
  mapCenter,
}: {
  map: any
  shadeMapRef: any
  mapCenter: any
}) => {
  const tooltip = document.getElementById('tooltip')
  const canvas: any = document.getElementById('canvas')
  const output = document.getElementById('output')
  const download = document.getElementById('download')

  let timeZone: any = 0
  let height = 0
  let width = 0
  let data: any = []
  const SUN_COLOR = [0, 173, 230, 255]
  const SHADE_COLOR = [0, 0, 0, 255]

  if (!canvas) return

  const YEAR = 2023

  const ctx = canvas.getContext('2d')

  ctx.imageSmoothingEnabled = false
  ctx.antialias = false

  timeZone = getTimeZone(map)

  const startDate = new Date(YEAR, 0, 1)
  const endDate = new Date(YEAR + 1, 0, 1)
  const timeOffset =
    UTCOffsetByTimeZone(
      startDate,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ) - UTCOffsetByTimeZone(startDate, timeZone)

  const profile = shadeMapRef.current._generateLocationShadeProfile({
    location: mapCenter,
    startDate: new Date(startDate.getTime() - timeOffset),
    endDate: new Date(endDate.getTime() - timeOffset),
    sunColor: SUN_COLOR,
    shadeColor: SHADE_COLOR,
  })

  data = profile.data
  height = profile.height
  width = profile.width

  let offset = 0
  let startDST = 0
  let DSTOffset = 0
  let endDST = 0

  for (let i = 0; i < width; i += 1) {
    const date =
      new Date(startDate.getTime() - timeOffset).getTime() +
      1000 * 60 * 60 * 24 * i
    const temp = UTCOffsetByTimeZone(new Date(date), timeZone)

    if (offset !== 0 && temp !== offset) {
      if (startDST === 0) {
        startDST = i
        DSTOffset = (offset - temp) / 1000 / 60
      } else {
        endDST = i
      }
    }
    offset = temp
  }

  // calculate minutes of sun
  let minutesOfSun = 0

  for (let i = 0; i < data.length; i += 4) {
    if (
      data[i] === SUN_COLOR[0] &&
      data[i + 1] === SUN_COLOR[1] &&
      data[i + 2] === SUN_COLOR[2] &&
      data[i + 3] === SUN_COLOR[3]
    ) {
      minutesOfSun += 1
    }
  }
  const days = Math.floor(minutesOfSun / (60 * 24))
  const hours = Math.floor((minutesOfSun - days * 60 * 24) / 60)
  const minutes = minutesOfSun - days * 60 * 24 - hours * 60

  if (output)
    output.innerText = `Annual sunlight: ${days} days, ${hours} hours, ${minutes} minutes`

  const imageData = new ImageData(
    new Uint8ClampedArray(data.buffer),
    width,
    height,
  )

  const bitmap = await createImageBitmap(imageData)
  const rect = canvas.getBoundingClientRect()

  canvas.width = rect.width * devicePixelRatio
  canvas.height = rect.height * devicePixelRatio

  ctx.drawImage(bitmap, 0, 0, width, height, 0, 0, canvas.width, canvas.height)

  if (startDST !== 0 && endDST !== 0) {
    ctx.drawImage(
      bitmap,
      startDST,
      0,
      endDST - startDST,
      height,
      (startDST / width) * canvas.width,
      (-DSTOffset / (24 * 60)) * canvas.height,
      ((endDST - startDST) / width) * canvas.width,
      canvas.height,
    )
  }
  ctx.font = '1em sans-serif'
  ctx.fillStyle = 'white'
  ctx.strokeStyle = 'white'

  ctx.beginPath()

  // hours, y-axis
  for (let i = 0; i < canvas.height; i += canvas.height / 24) {
    ctx.moveTo(0, i)
    ctx.lineTo(canvas.width, i)
  }
  for (let i = 1; i < 24; i += 1) {
    ctx.fillText(i, 3, (canvas.height / 24) * (24 - i) - 10)
  }

  // months, x-axis
  let xOffset = 0

  for (let i = 1; i <= 12; i += 1) {
    const month: any = new Date(new Date().getFullYear(), i, 0)
    const days = month.getDate()
    const monthName = month.toLocaleDateString([], {
      month: 'short',
    })

    ctx.fillText(
      monthName,
      (xOffset / profile.width) * canvas.width + 10,
      canvas.height - 10,
    )
    xOffset += days
    ctx.moveTo((xOffset / profile.width) * canvas.width, 0)
    ctx.lineTo((xOffset / profile.width) * canvas.width, canvas.height)
  }
  ctx.stroke()

  canvas.addEventListener('mousemove', (e: any) => {
    if (timeZone === 0) {
      return
    }
    const rect = e.target.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    // TODO width contains total days in YEAR i.e. 365/366
    const day = Math.floor((x / rect.width) * width)
    const time = Math.floor((1 - y / rect.height) * 24 * 60)
    const hours = Math.floor(time / 60)
    const minutes = Math.floor(time % 60)

    // day of year, hour, minutes, year is 2023
    const startOfYear = new Date(YEAR, 0)
    const startOfDay = new Date(startOfYear.setDate(day))
    const dayWithTime = startOfDay.setHours(hours, minutes)
    const timeOffset =
      UTCOffsetByTimeZone(
        new Date(dayWithTime),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      ) - UTCOffsetByTimeZone(new Date(dayWithTime), timeZone)
    const date = new Date(dayWithTime - timeOffset)

    if (tooltip) {
      tooltip.style.left = `${e.clientX + 10}px`
      tooltip.style.top = `${e.clientY + 10}px`
      tooltip.innerText = date.toLocaleString([], { timeZone })
    }

    shadeMapRef.current.setDate(date)
  })

  if (download) {
    download.addEventListener('click', () => {
      const dayData = []

      for (let i = 0; i < width; i += 1) {
        let minutesOfSun = 0

        for (let j = 0; j < height; j += 1) {
          const index = j * width * 4 + i * 4

          if (
            data[index] === SUN_COLOR[0] &&
            data[index + 1] === SUN_COLOR[1] &&
            data[index + 2] === SUN_COLOR[2] &&
            data[index + 3] === SUN_COLOR[3]
          ) {
            minutesOfSun += 1
          }
        }
        dayData.push(minutesOfSun)
      }

      const json = JSON.stringify(
        {
          description: `Each data array position corresponds to the day of the year 0-${width}. The array value corresponds to minutes of direct sunlight.`,
          position: {
            lng: map.getCenter().lng,
            lat: map.getCenter().lat,
          },
          minutesOfDirectSunPerDay: dayData,
          minutesOfDirectSunPerYear: dayData.reduce(
            (prev, cur) => prev + cur,
            0,
          ),
        },
        null,
        2,
      )

      const blob = new Blob([json], { type: 'application/json' })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')

      a.href = url
      a.download = 'sun_data.json'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  }
}

export { buildShadeMap, getTimeZone, UTCOffsetByTimeZone, generateProfile }
