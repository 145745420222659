/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { useRouter, useI18n } from '@popety_io/popety-io-lib'

const queries = ['city_history', 'district_history', 'radius']

const LocationsFilter = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const [value, setValue] = useState(null)

  const handleChangeLocation = (e: any, newValue: any) => {
    const val = newValue?.value || newValue
    const title =
      newValue?.group === t('land.history.radius')
        ? 'radius'
        : newValue?.group === t('land.city')
          ? 'city_history'
          : 'district_history'

    setValue(newValue)

    const deleteTitle = queries
      .filter((qry) => qry !== title)
      .map((ttl) => [ttl, undefined])

    const query = {
      [title]: val,
      ...Object.fromEntries(deleteTitle as any),
    }

    updateQuery(query)
  }

  useEffect(() => {
    const locationScope =
      (query.radius && Number(query.radius)) ||
      query.city_history ||
      query.district_history

    if (!locationScope) {
      updateQuery({
        radius: 500,
      })
    }
  }, [])

  return (
    <Grid item xs={12} sm={6} md={3} id="location-filter">
      <Autocomplete
        groupBy={(option) => option.group}
        filterSelectedOptions
        getOptionLabel={(option: any) => option.label || option.value}
        isOptionEqualToValue={(option: any, val: any) =>
          option.value === (val?.value ?? val)
        }
        selectOnFocus
        autoHighlight
        value={value}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={t('common.selectModel')}
            variant="outlined"
            size="small"
            margin="none"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: { autoComplete: 'off' },
            }}
          />
        )}
        onChange={handleChangeLocation}
        options={[]}
        fullWidth
      />
    </Grid>
  )
}

export default LocationsFilter
