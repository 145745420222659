import { Box, BoxProps, ToggleButtonGroup } from '@mui/material'
import { FC, memo } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import { useDispatch } from 'react-redux'
import { IBuildOption } from '../components/Map/basemap.util'
import { changeMapStyle } from '../../Estimate/redux'

export type ControlMapStyleSwitchProps = BoxProps & {
  options: Array<IBuildOption>
}
const ControlMapStyleSwitch: FC<ControlMapStyleSwitchProps> = ({
  options,
  ...other
}) => {
  const dispatch = useDispatch()

  const handleClick = (value: string) => () => {
    dispatch(changeMapStyle(value))
  }

  return (
    <Box
      position="absolute"
      p="3px"
      pb={0}
      sx={{ cursor: 'pointer' }}
      bgcolor="background.paper"
      borderRadius={2}
      right={10}
      padding={0}
      bottom={292}
      overflow="hidden"
      {...other}
    >
      <ToggleButtonGroup orientation="vertical" exclusive>
        {options.map((option) => (
          <ToggleButton
            value="list"
            aria-label="list"
            key={option.value}
            onClick={handleClick(option.value)}
            sx={{
              padding: '2px',
              opacity: option.active ? 1 : 0.5,
              backgroundColor: option.active ? '#00ade6' : '#dddddd',
              overflow: 'hidden',
            }}
          >
            <img alt="prospect" width={24} height={24} src={option.src} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  )
}

export default memo(ControlMapStyleSwitch)
