/* eslint-disable max-lines */

import { createSelector } from '@reduxjs/toolkit'
import {
  computeAdditionalCost,
  computeConstructionCost,
  computeLandCost,
  computePreliminaryCost,
  computeSellingCost,
} from './helper'

const landingLoadingSelector = (state) => state?.landSummary?.loading
const landingErrorSelector = (state) => state?.landSummary?.error
const summarySelector = (state) => state?.landSummary?.landsValue
const landSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.land
const landIdSelector = (state) => state?.landSummary?.landsValue?.data?.land_id
const creationDateSelector = (state) => state?.landSummary?.creationDate

const landIdStringSelector = createSelector(landIdSelector, (landId) => {
  return landId ? `${landId}` : null
})

const biggestLandUseSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.bigestLandUsePlan
const landTagsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.activeUniqueLandTags
const userSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.user
const officeSelector = (state) => state?.landSummary?.office
const sectionsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.sections
const landHistorySelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.landHistory
const groupLandHistroySelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.groupLandHistroy
const geoCenterSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.geoCenter
const landLayerSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.landLayer
const geoPolygonSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.geoPolygon
const livabilityIsochroneSelector = (state) =>
  state?.landSummary?.data?.value?.livabilityIsochrone
const geoSelector = (state) => state?.landSummary?.landsValue?.data?.value?.geo
const rdppfsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.rdppfs
const authorizedLimitSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.authorizedLimit
const buildingSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.buildings
const summaryMapSelectedLayersSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.summaryMapSelectedLayers
const landLayersOutlinedSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.landLayersOutlined
const landLayersOutlinedDasharraySelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.landLayersOutlinedDasharray
const customSunlightBuildingSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.customSunlightBuilding
const generatedBuildingSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.generatedBuilding
const displayPoiSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.displayPoi
const transportTypeSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.transportType
const landGeoCenterSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.landGeoCenter
const poisSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.pois
const sunlightHoursSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.sunlightHours
const landUsePlansSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.landUsePlans
const specialPlansSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.specialPlans
const futureLandUsePlansDocumentsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.futureLandUsePlansDocuments
const sortByOfficialIdDocumentsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.sortByOfficialIdDocuments
const importantDocumentsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.importantDocuments
const groupingIdSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.groupingId
const noiseSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.noise
const rawTransactionsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.rawTransactions
const faosSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.faos
const listingsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.listings
const faoCountSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.faoCount
const transactionCountSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.transactionCount
const listingCountSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.listingCount
const buildingsByIdSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.buildingsById
const protectedBuildingsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.protectedBuildings
const buildingsLengthSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.buildingsLength
const altimetrySelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.altimetry
const altimetryDataSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.altimetryData
const tiltaverageSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.tiltaverage
const scoreSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.score
const groupedPoiCategoriesSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.groupedPoiCategories
const poiCategorySelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.poiCategory
const landsSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.lands
const poisNumberSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.poisNumber
const selectedAnalysisSelector = (state) => state?.landSummary?.selectedAnaylyst
const analysisListSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.analysisList

const authorizedCosSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.authorizedCos
const authorizedCusSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.authorizedCus
const landAuthorizedInfoSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.landAuthorizedInfo
const financialPlanSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.financialPlan

const actionHistoriesSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.actionHistories
const filesSelector = (state) =>
  state?.landSummary?.landsValue?.data?.value?.files
const landCostSelector = createSelector(
  selectedAnalysisSelector,
  computeLandCost,
)
const preliminaryCostSelector = createSelector(
  selectedAnalysisSelector,
  computePreliminaryCost,
)
const constructionCostSelector = createSelector(
  selectedAnalysisSelector,
  computeConstructionCost,
)
const totalCostNoAdditionalSelector = createSelector(
  landCostSelector,
  preliminaryCostSelector,
  constructionCostSelector,
  ({ landCostTotal }, { preliminaryCostTotal }, { constructionCostTotal }) =>
    Math.round(landCostTotal + preliminaryCostTotal + constructionCostTotal),
)
const additionalCostSelector = createSelector(
  selectedAnalysisSelector,
  totalCostNoAdditionalSelector,
  computeAdditionalCost,
)
const projectCostTotalSelector = createSelector(
  additionalCostSelector,
  totalCostNoAdditionalSelector,
  constructionCostSelector,
  (additionalCost, totalCostNoAdditional) =>
    Math.round(additionalCost.additionalCostTotal + totalCostNoAdditional),
)
const sellingCostSelector = createSelector(
  selectedAnalysisSelector,
  projectCostTotalSelector,
  computeSellingCost,
)

export {
  authorizedLimitSelector,
  biggestLandUseSelector,
  buildingSelector,
  customSunlightBuildingSelector,
  displayPoiSelector,
  faosSelector,
  futureLandUsePlansDocumentsSelector,
  generatedBuildingSelector,
  geoCenterSelector,
  geoPolygonSelector,
  geoSelector,
  groupingIdSelector,
  groupLandHistroySelector,
  importantDocumentsSelector,
  landGeoCenterSelector,
  landHistorySelector,
  landIdSelector,
  landIdStringSelector,
  landingErrorSelector,
  landingLoadingSelector,
  landLayerSelector,
  landLayersOutlinedDasharraySelector,
  landLayersOutlinedSelector,
  landSelector,
  landTagsSelector,
  landUsePlansSelector,
  listingsSelector,
  livabilityIsochroneSelector,
  noiseSelector,
  poisSelector,
  rawTransactionsSelector,
  rdppfsSelector,
  sectionsSelector,
  sortByOfficialIdDocumentsSelector,
  specialPlansSelector,
  summaryMapSelectedLayersSelector,
  summarySelector,
  sunlightHoursSelector,
  transportTypeSelector,
  userSelector,
  faoCountSelector,
  transactionCountSelector,
  listingCountSelector,
  buildingsByIdSelector,
  protectedBuildingsSelector,
  buildingsLengthSelector,
  altimetrySelector,
  altimetryDataSelector,
  tiltaverageSelector,
  scoreSelector,
  groupedPoiCategoriesSelector,
  poiCategorySelector,
  landsSelector,
  poisNumberSelector,
  selectedAnalysisSelector,
  analysisListSelector,
  authorizedCosSelector,
  authorizedCusSelector,
  landAuthorizedInfoSelector,
  financialPlanSelector,
  projectCostTotalSelector,
  sellingCostSelector,
  officeSelector,
  creationDateSelector,
  actionHistoriesSelector,
  filesSelector,
  landCostSelector,
  preliminaryCostSelector,
  constructionCostSelector,
  additionalCostSelector,
}
