/* eslint-disable max-lines */
import { getColorDwellings } from '@popety_io/popety-io-lib'

export const getAddressFloor = (
  floor: number,
  t: any,
  isHouse?: boolean,
  floorNb?: number,
) => {
  if (isHouse) {
    const floors = Array.from({ length: floorNb || 0 })
      .map((_, i) => {
        if (i === 0) {
          return t('building.configuration.Rez de chaussée')
        }

        return t('building.configuration.Étage', { floor: i })
      })
      .join(', ')

    return floors
  }

  if (floor === 0) {
    return t('building.configuration.Rez de chaussée')
  }

  return t('building.configuration.Étage', { floor })
}

export const getTypeFloor = (
  popetyClassification: string,
  option: any,
  t: any,
  floorArea: number,
  buildingArea: number,
  unitType?: string,
) => {
  let dwellingEdit: any = null

  option.forEach((o: any) => {
    const type = o?.type?.edited

    if (dwellingEdit && type !== dwellingEdit) {
      dwellingEdit = t('common.classification.Mixed')

      return
    }

    if (type && !dwellingEdit) {
      dwellingEdit = type
    }

    if (Number.isInteger(Number(type))) {
      dwellingEdit = t('common.classification.Residential')
    }
  })

  if (dwellingEdit && !unitType) {
    return dwellingEdit
  }

  if (
    (!unitType && popetyClassification === 'Mixed_1') ||
    (!unitType && popetyClassification === 'Mixed_2')
  ) {
    const dwellingsAreaPercentage = (floorArea / buildingArea) * 100

    if (dwellingsAreaPercentage === 0) {
      return t('common.classification.Not residential')
    }

    if (dwellingsAreaPercentage < 50) {
      return t('common.classification.Mixed')
    }

    if (dwellingsAreaPercentage >= 50) {
      return t('common.classification.Residential')
    }

    if (!option?.[0]?.noDwellings) {
      return t('common.classification.Mixed')
    }

    return t('common.classification.Not residential')
  }

  switch (unitType) {
    case 'Retail':
      return t('common.type.floor.Retail')
    case 'Residential':
      return t('common.classification.Residential')
    case 'Public':
      return t('common.type.floor.Public')
    case 'Other':
      return t('common.type.floor.Other')
    case 'Offices':
      return t('common.type.floor.Offices')
    case 'Not classified':
      return t('common.type.floor.Not classified')
    case 'Industrial':
      return t('common.type.floor.Industrial')
    case 'Garage':
      return t('common.type.floor.Garage')
    case 'Not residential':
      return t('common.classification.Not residential')
    case 'Mixed':
      return t('common.classification.Mixed')
    default:
      return t(`common.type.floor.${unitType || popetyClassification}`)
  }
}

export const unitTypeValue = ['Offices', 'Retail', 'Industrial', 'Garage']

export const getOptionsConfiguration = (
  floorNb: number,
  t: any,
  isHouse?: boolean,
  ba?: any,
) => {
  // const addressId = address?.id || address?.address_id
  const buildingAdminId = ba?.id
  let floors: any[] = []

  ba?.addresses?.forEach((a: any) => {
    a?.dwellings?.forEach((dwelling: { floor: number }) => {
      floors[dwelling?.floor] = [
        ...(floors[dwelling?.floor] || []),
        { ...dwelling, edid: a?.edid?.toString() },
      ]
    })
  })

  floors = Object.values(floors) || []

  const floorsWithDwellings: any = floors.map((floor) => {
    return [...floor]
      .sort((a: { ewid: number }, b: { ewid: number }) => a.ewid - b.ewid)
      .map((d: any) => {
        const buildingYear =
          d?.building_year === 1999 ? '<1999' : d?.building_year || '-'

        let data = {
          id: d.id,
          fid: { value: d.ewid || '-', startAdornment: '#' },
          edid: { value: d.edid || '-' },
          type: {
            value: d.room_nb || 1,
            name: d.room_nb ? `${d.room_nb} ${t('common.rooms')}` : 1,
            options: [
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
              { label: 6, value: 6 },
              { label: 7, value: 7 },
              { label: 8, value: 8 },
              { label: 9, value: 9 },
              { label: 10, value: 10 },
              { label: 'Offices', value: 'Offices' },
              { label: 'Retail', value: 'Retail' },
              { label: 'Industrial', value: 'Industrial' },
              { label: 'Garage', value: 'Garage' },
            ].map((i) =>
              Number.isInteger(i.label)
                ? { ...i, label: `${i.label} ${t('common.rooms')}` }
                : { ...i, label: t(`common.type.floor.${i.label}`) },
            ),
          },
          adminN: { value: d.administrative_nb || '-' },
          physN: { value: d.physical_nb || '-' },
          situation: { value: d.floor_location || '-' },
          cYear: { value: buildingYear },
          area: { value: d.area || '-', endAdornment: d.area && 'm2' },
          rooms: { value: d.room_nb || '-' },
          bathR: { value: '' },
          multiFL: {
            value: d.multiple_floor ? t('common.yes') : t('common.no'),
            options: [t('common.no'), t('common.yes')],
          },
          floor: d.floor,
          buildingAdminId,
        }

        data = {
          ...data,
          type: {
            ...data.type,
            startAdornmentcolor: getColorDwellings(d.room_nb || 1),
          } as any,
        }

        return data
      })
  })

  const floorsWithEmpty = Array.from({ length: isHouse ? 1 : floorNb }).map(
    (_, i) => {
      const foundFloorWithDwellings = floorsWithDwellings.find((o: any) => {
        return (
          o[0]?.floor === i &&
          (o[0]?.buildingAdminId === buildingAdminId || !o[0]?.buildingAdminId)
        )
      })

      if (foundFloorWithDwellings) return foundFloorWithDwellings

      let data = {
        id: Math.random() * 1000,
        fid: { value: '-' },
        edid: { value: '-' },
        type: {
          options: [
            { label: 1, value: 1 },
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
            { label: 5, value: 5 },
            { label: 6, value: 6 },
            { label: 7, value: 7 },
            { label: 8, value: 8 },
            { label: 9, value: 9 },
            { label: 10, value: 10 },
            { label: 'Offices', value: 'Offices' },
            { label: 'Retail', value: 'Retail' },
            { label: 'Industrial', value: 'Industrial' },
            { label: 'Garage', value: 'Garage' },
          ].map((i) =>
            Number.isInteger(i.label)
              ? { ...i, label: `${i.label} ${t('common.rooms')}` }
              : { ...i, label: t(`common.type.floor.${i.label}`) },
          ),
        },
        adminN: { value: '-' },
        physN: { value: '-' },
        situation: { value: '-' },
        cYear: { value: '-' },
        area: { value: '-' },
        rooms: { value: '-' },
        bathR: { value: '' },
        multiFL: {
          options: [t('common.no'), t('common.yes')],
        },
        floor: i,
        noDwellings: true,
        buildingAdminId,
      }

      data = {
        ...data,
        type: {
          ...data.type,
        } as any,
      }

      return [data]
    },
  )

  const newConfigurationEdited = []?.filter(
    (config: { isEdit?: boolean }) => config?.isEdit,
  )

  const options = floorsWithEmpty.map((floor) => {
    const foundEditedDweelings = newConfigurationEdited.filter(
      (d: any) =>
        d.floor === floor[0].floor && d.buildingAdminId === buildingAdminId,
    )

    if (foundEditedDweelings?.length) {
      return [...floor, ...foundEditedDweelings]
    }

    return floor
  })

  return options
}

export const getLogementOptions = (t: any) => [
  { label: t('common.classification.Retail'), value: 'Retail' },
  {
    label: t('common.classification.Residential'),
    value: 'Residential',
  },
  {
    label: t('common.type.floor.Public'),
    value: 'Public',
  },
  {
    label: t('common.type.floor.Other'),
    value: 'Other',
  },
  {
    label: t('common.type.floor.Offices'),
    value: 'Offices',
  },
  {
    label: t('common.type.floor.Not classified'),
    value: 'Not classified',
  },
  {
    label: t('common.type.floor.Industrial'),
    value: 'Industrial',
  },
  {
    label: t('common.type.floor.Garage'),
    value: 'Garage',
  },
  {
    label: t('common.classification.Not residential'),
    value: 'Not residential',
  },
  {
    label: t('common.classification.Mixed'),
    value: 'Mixed',
  },
]

export const getFloorOptions = (t: any) => [
  {
    label: t('building.configuration.Rez de chaussée'),
    value: 'Ground floor',
  },
  {
    label: t('building.Etage'),
    value: 'Floor',
  },
]
