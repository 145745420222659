import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useTheme } from '@mui/material'

const ListingFooter = ({ listing }: { listing: any }) => {
  const { t } = useI18n()

  const theme = useTheme()

  const isListing =
    listing.original_url === '' ||
    (!!listing.original_url && !listing.ad_status)

  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#ffffff',
        paddingTop: 1,
        paddingBottom: 1,
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" gap={1}>
          <Button
            variant="contained"
            onClick={() => {
              window.open(listing.original_url, '_blank')
            }}
            disabled={isListing}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              backgroundColor: '#ffffff',
              color: theme.palette.primary.main,
              '&:hover': { backgroundColor: '#fff' },
            }}
          >
            <OpenInNewIcon />
            {t('common.view_comparable')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ListingFooter
