import { useRouter } from '@popety_io/popety-io-lib'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'

import { useTheme } from '@mui/material'
import { getMultipleLandLayersOultined } from '../../Estimate/components/EstimateMap/EstimateMap.utils'
import { getBuildingLayers } from '../../LandDetail/Building/Building.layers'
import {
  getPlotLocation,
  getPlotRadius,
} from '../../LandDetail/components/Map/utils'
import {
  buildingIdStrSelector,
  buildingSelector,
  configurationSelector,
  geoPolygonSelector,
  landsSelector,
  livabilityIsochroneSelector,
  roofPolygonSelector,
} from '../redux'
import { getBuildingLayer } from './BuildingDetailsMap.utils'

const useBuildingDetailsMapLayers = () => {
  const { query } = useRouter()
  const location = useLocation()
  const theme = useTheme()

  const { radius, dimension = '3D', egid } = query
  const isBuildingDetails = location.pathname === '/land-detail/building'
  const color = theme?.palette?.primary?.main || '#00ade6'

  const building = useSelector(buildingSelector)

  const geoPolygon = useSelector(geoPolygonSelector)
  const livabilityIsochrone = useSelector(livabilityIsochroneSelector)

  const radiusLayer = useMemo(() => {
    if (!building?.geo_center) {
      return null
    }
    if (isBuildingDetails && !radius) {
      return null
    }

    return getPlotRadius(building.geo_center, radius)
  }, [isBuildingDetails, building?.geo_center, radius])

  const locationLayer = getPlotLocation(geoPolygon)
  const lands = useSelector(landsSelector)

  const roofPolygon = useSelector(roofPolygonSelector)
  const configuration = useSelector(configurationSelector)
  const buildingsById = useSelector(buildingIdStrSelector)

  const buildingsLayers = useMemo(() => {
    if (buildingsById) {
      return getBuildingLayers(
        Object.values(buildingsById).filter(
          (buildingItem: any) => buildingItem.id !== building.id,
        ),
        dimension,
      )
    }
  }, [buildingsById])

  const landsLayers: any = useMemo(() => {
    return getMultipleLandLayersOultined(lands, color)
  }, [lands])

  const buildingLayer = useMemo(() => {
    return getBuildingLayer(
      building,
      query?.energyLayer,
      query?.tab,
      query?.buildingHeight,
      egid,
      roofPolygon,
      configuration,
      dimension,
    )
  }, [
    building,
    query?.energyLayer,
    query?.tab,
    query?.buildingHeight,
    configuration,
    dimension,
    egid,
  ])

  const history = [
    buildingLayer,
    query.city_history && locationLayer,
    query.district_history && locationLayer,
    query.npa_history && locationLayer,
    radiusLayer,
    ...landsLayers,
  ].filter(Boolean)

  const sourcesByPage: Record<string, any[][]> = {
    summary: [buildingLayer],
    parcelle: [...landsLayers, buildingsLayers, buildingLayer],
    transaction: history,
    fao: history,
    listing: history,
    livability: [livabilityIsochrone, buildingLayer],
    sunlight: [buildingLayer],
    noise: [landsLayers],
    history: [...landsLayers],
  }

  const layers =
    sourcesByPage[query.subTab || query.tab] || sourcesByPage.summary

  const data = layers?.flat()?.filter(Boolean)

  return { data }
}

export { useBuildingDetailsMapLayers }
