/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { Map, useI18n, useRouter } from '@popety_io/popety-io-lib'
import mapboxgl from 'mapbox-gl'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  buildingSelector,
  displayPoiSelector,
  geoCenterSelector,
  poisSelector,
} from '../../redux/summary'
import { createLivabilityIcon } from '../Livability.util'
import { displayPois, removePoisTiles } from './utils'

const LivabilityMapController = ({
  map,
  loaded,
}: {
  map: Map
  loaded?: boolean
}) => {
  const { t } = useI18n()
  const { query } = useRouter()
  const poisCategories: string = query.poisCategories || ''
  const building = useSelector(buildingSelector)
  const landGeoCenter = useSelector(geoCenterSelector)
  const pois = useSelector(poisSelector)
  const display = useSelector(displayPoiSelector)
  const geoCenter = building?.geo_center || landGeoCenter
  const [mapMarkers, setMapMarkers] = useState<any[]>([])
  const [newFeatures, setNewFeatures] = useState<any[]>([])

  const poisCategoriesArray = useMemo(
    () =>
      poisCategories
        .replaceAll(', ', '%%')
        .split(',')
        .map((i) => i.replaceAll('%%', ', ')),
    [poisCategories],
  )

  const filteredPois = useMemo(
    () =>
      poisCategories.length
        ? pois?.filter(
            (poi: { subcategory: string }) =>
              !poisCategoriesArray?.includes(poi.subcategory),
          )
        : pois,
    [pois, poisCategories, poisCategoriesArray],
  )

  const isLivabilityTab =
    query.tab === 'livability' || query.subTab === 'livability'

  const { title } = query

  useEffect(() => {
    if (!map || !loaded || !isLivabilityTab) return

    if (mapMarkers.length > 0) {
      mapMarkers.forEach((marker) => marker.remove())
      setMapMarkers([])
    }

    if (!display) {
      mapMarkers.forEach((marker) => marker.remove())
      setMapMarkers([])

      return
    }

    const markers = newFeatures
      .map((feature: any) => {
        if (!display) return null

        const { properties } = feature

        let center: any

        if (properties.geo_center_lon && properties.geo_center_lat) {
          center = [properties.geo_center_lon, properties.geo_center_lat]
        } else if (properties.geo_polygon_lat && properties.geo_polygon_lon) {
          center = [properties.geo_polygon_lon, properties.geo_polygon_lat]
        }

        if (!center) return null

        const el = createLivabilityIcon(properties.categoryKey)
        const marker = new mapboxgl.Marker(el).setLngLat(center).addTo(map)

        marker.getElement().addEventListener('mouseenter', () => {
          let description = ''

          if (properties.type) {
            description += `<p style="font-size: 16px;font-weight: 500;">${t(`land.livability.type.${properties.type}`)}</p>`
          }
          if (properties.subcategory) {
            description += `<p>${properties.subcategory}</p>`
          }
          if (properties.name) {
            description += `<p>${properties.name}</p>`
          }

          new mapboxgl.Popup({
            closeButton: false,
            offset: 25,
          })
            .setLngLat(center)
            .setHTML(description)
            .addTo(map)
        })
        marker.getElement().addEventListener('mouseleave', () => {
          // Remove any existing popups when mouse leaves marker
          const popups = document.getElementsByClassName('mapboxgl-popup')

          while (popups[0]) {
            popups[0].parentNode?.removeChild(popups[0])
          }
        })

        return marker
      })
      .filter(Boolean)

    if (display) {
      setMapMarkers(markers)
    }
  }, [newFeatures, display])

  const addPoisTiles = () => {
    try {
      displayPois(map, filteredPois)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!isLivabilityTab && map && loaded) {
      removePoisTiles({ map })
      mapMarkers.forEach((marker) => marker.remove())
      setMapMarkers([])
    }
  }, [query?.tab, query?.subTab])

  useEffect(() => {
    if (!map || !loaded || !isLivabilityTab || !geoCenter) return

    const addEvents = () => {
      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = ''
      })
      map.on('click', 'clusters', (e: any) => {
        const features: any = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })
        const clusterId = features[0]?.properties?.cluster_id

        const src = map.getSource('es_mvt_poi') as any

        src.getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
          if (err) return

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          })
        })
      })
      map.on('data' as any, 'unclustered-point', (e: any) => {
        if (e.features) {
          setNewFeatures(e.features)
        }
      })
    }
    const removeEvents = () => {
      map.off('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.off('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = ''
      })
      map.off('click', 'clusters', (e: any) => {
        const features: any = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })
        const clusterId = features[0]?.properties?.cluster_id

        const src = map.getSource('es_mvt_poi') as any

        src.getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
          if (err) return

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          })
        })
      })
      map.off('data' as any, 'unclustered-point', () => {
        setNewFeatures([])
      })
    }

    if (display) {
      addPoisTiles()
      map.on('style.load', addPoisTiles)
      addEvents()
    } else {
      removeEvents()
      removePoisTiles({ map })
    }

    return () => {
      map.off('style.load', addPoisTiles)
      removeEvents()
    }
  }, [isLivabilityTab, loaded, geoCenter, title, filteredPois, display])

  if (!isLivabilityTab || !map || !loaded) return null

  return <></>
}

export default LivabilityMapController
