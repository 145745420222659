import { combineReducers } from '@reduxjs/toolkit'
import { landSummaryReducer } from '../pages/LandDetail/redux/summary'

import { basemapReducer, estimateReducer } from '../pages/Estimate/redux'
import { sunlightReducer } from '../pages/LandDetail/redux/sunlight'

import { buildingSummaryReducer } from '../pages/BuildingDetails/redux'
import { appReducer } from './appSlice'

const rootReducer = combineReducers({
  ...appReducer,
  ...basemapReducer,
  ...sunlightReducer,
  ...landSummaryReducer,
  ...estimateReducer,
  ...buildingSummaryReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }

  return rootReducer(state, action)
}
