import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getLandSummary = ({ hash }: any) => {
  return client.get(`/public/land?hash=${hash}`)
}
const getPublicFile = ({
  type,
  hash,
  fileId,
}: {
  type: string
  hash: string
  fileId?: string
}) => {
  return client.downloadFile(`/public/file/${type}/${hash}/${fileId}`)
}

const summaryService = {
  getLandSummary,
  getPublicFile,
}

export default summaryService
