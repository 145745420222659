import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { memo, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { useDispatch, useSelector } from 'react-redux'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { getMonths, getHours, useRouter } from '@popety_io/popety-io-lib'
import TextField, { TextFieldProps } from '@mui/material/TextField'

import { sunDateSelector, updateSunlightOptions } from '../../redux'

interface MyComponentProps {
  // Other props...
  sx?: TextFieldProps['sx'] // Specify the type for sx
}

const SunMapControls = ({ sx }: MyComponentProps) => {
  const sunlightDate = useSelector(sunDateSelector)

  const { query } = useRouter()

  const sunTime = useMemo(
    () => new Date(sunlightDate).getTime(),
    [sunlightDate],
  )

  const [monthValue, setMonthValue] = useState<number>(sunTime)
  const [timeValue, setTimeValue] = useState<number>(sunTime)

  const months = useMemo(() => getMonths('MMM', sunlightDate), [sunlightDate])

  const monthMark = useMemo(
    () =>
      [months[0], months[months.length - 1]].map((i) => ({
        ...i,
        value: i.date.getTime(),
      })),
    [JSON.stringify(months)],
  )
  const times = useMemo(() => getHours('MMM', sunlightDate), [sunlightDate])

  const timeMark = useMemo(
    () =>
      [times[0], times[months.length - 1]].map((i) => ({
        ...i,
        value: i.date.getTime(),
      })),

    [],
  )

  const dispatch = useDispatch()

  const handleMonthRangeCommitted = (e: any, val: any) => {
    const date = new Date(val)

    const sunDate = new Date(sunlightDate)

    date.setHours(
      sunDate.getHours(),
      sunDate.getMinutes(),
      sunDate.getSeconds(),
    )

    dispatch(updateSunlightOptions({ date }))
  }

  const handleTimeRangeCommitted = (e: any, val: any) => {
    dispatch(updateSunlightOptions({ date: new Date(val) }))
  }

  const handleMonthRangeChange = (e: any, val: any) => {
    setMonthValue(val)
    handleMonthRangeCommitted(e, val)
  }

  const handleTimeRangeChange = (e: any, val: any) => {
    setTimeValue(val)
    const selectedTime = new Date(val)
    const dayDate = new Date(sunlightDate)

    dayDate.setHours(selectedTime.getHours())
    dayDate.setMinutes(selectedTime.getMinutes())
    dayDate.setSeconds(selectedTime.getSeconds())
    handleTimeRangeCommitted(e, dayDate)
  }

  const handleDateChange = (date: any) => {
    dispatch(updateSunlightOptions({ date: new Date(date) }))
  }

  return (
    <>
      <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center">
        <Slider
          onChange={handleMonthRangeChange}
          valueLabelDisplay="off"
          value={monthValue}
          aria-labelledby="sun-month"
          min={monthMark[0].value}
          max={monthMark[1].value}
          marks={monthMark}
          sx={{
            minWidth: '230px',
            margin: ' 0 14px',
            marginBottom: '14px',
            marginRight: '15px',
            width: 'unset',
          }}
        />

        <DatePicker
          openTo="day"
          views={['day']}
          value={sunlightDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} fullWidth sx={sx} />}
        />
      </Box>
      {query.sunLightTab !== 'sunExposer' && (
        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center">
          <Slider
            onChange={handleTimeRangeChange}
            valueLabelDisplay="off"
            value={timeValue}
            aria-labelledby="sun-time"
            min={timeMark[0].value}
            max={timeMark[1].value}
            marks={timeMark}
            sx={{
              minWidth: '230px',
              margin: ' 0 14px',
              marginBottom: '14px',
              marginRight: '15px',
              width: 'unset',
            }}
          />
          <TimePicker
            value={sunlightDate}
            onChange={handleDateChange}
            renderInput={(params: any) => (
              <TextField {...params} fullWidth sx={sx} />
            )}
          />
        </Box>
      )}
    </>
  )
}

export default memo(SunMapControls)
