/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */

import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { useEffect, useMemo } from 'react'

import { Box } from '@mui/material'

import MonthlyAverageExposure from '../../Estimate/components/EstimateEnergy/MonthlyAverageExposure'
import {
  potentialSolar,
  potentialSolarColor,
} from '../../LandDetail/Building/Building.utils'
import {
  buildingPotentialSolarDataSelector,
  buildingSelector,
  heatConsumptionAroundSelector,
  monthlyAverageExposureSelector,
  userDetailedDataSelector,
} from '../redux'
import EnergyHeader from './EnergyHeader'
import EnergyHeatConsumptionAround from './EnergyHeatConsumptionAround'
import HeatingAndHotWaterSection from './HeatingAndHotWaterSection'
// import CECBSection from './CECBSection'

import CurrentSolaireInstalationSection from './CurrentSolaireInstalationSection'
import PotetialSolaireSection from './PotetialSolaireSection'

const Energy = () => {
  const { t } = useI18n()

  const building = useSelector(buildingSelector)
  const userDetailed = useSelector(userDetailedDataSelector)
  const isLoading = false
  const monthlyAverageExposure = useSelector(monthlyAverageExposureSelector)
  const buildingPotentialSolar = useSelector(buildingPotentialSolarDataSelector)
  const heatConsumptionAround = useSelector(heatConsumptionAroundSelector)
  const heatConsumptionAroundLoading = false

  const { query, updateQuery } = useRouter()

  const solarAverage = potentialSolar(
    t,
    buildingPotentialSolar?.average_exposure,
  )
  const solarAverageColor = potentialSolarColor(
    buildingPotentialSolar?.average_exposure,
  )

  const isGeneva = building?.region_name === 'Genève'

  // const updateCECB = async (values: ICECB) => {
  //   await energyService.updateCECB(building.id, values)
  //   dispatch(getUserDetailedEditing([building.id]))
  // }

  useEffect(() => {
    if (!query.energyLayer) {
      updateQuery({ energyLayer: 'power_source_heater' })
    }
  }, [])

  const heatConsumptionAroundData = useMemo(() => {
    return heatConsumptionAround?.buckets?.map((entry: any) => {
      if (entry?.from === 550) {
        return {
          ...entry,
          renovationIndice: 550,
          color: '#FFCB60',
        }
      }

      if (entry?.from === 650) {
        return {
          ...entry,
          renovationIndice: 650,
          color: '#FC8723',
        }
      }

      if (entry?.from === 800) {
        return {
          ...entry,
          renovationIndice: 800,
          color: '#E63900',
        }
      }

      if (
        building.heat_consumption_index > entry?.from &&
        building.heat_consumption_index < entry?.to
      ) {
        return {
          ...entry,
          currentHeatConsumption: building.heat_consumption_index,
        }
      }

      return entry
    })
  }, [heatConsumptionAround, building?.heat_consumption_index])

  return (
    <Box>
      <EnergyHeader building={building} />
      <HeatingAndHotWaterSection
        building={{
          ...building,
          last_update_timestamp_heater:
            building?.last_update_timestamp_heater?.split('T')[0],
          last_update_timestamp_water_heater:
            building?.last_update_timestamp_water_heater?.split('T')[0],
          last_update_timestamp_additional_heater:
            building?.last_update_timestamp_additional_heater?.split('T')[0],
          last_update_timestamp_additional_water_heater:
            building?.last_update_timestamp_additional_water_heater?.split(
              'T',
            )[0],
        }}
        heatingAndHotWaterEdited={userDetailed?.heatingAndHotWater}
        onSaveChange={() => {}}
        isLoading={isLoading}
      />
      {/* <CECBSection onSaveChange={updateCECB} defaultData={userDetailed?.cecb} /> */}
      <CurrentSolaireInstalationSection
        data={{
          ...building,
          instalationDate: [
            {
              kw: Number(building?.solar_installed_power),
              date: building?.solar_installed_first_date
                ? building?.solar_installed_first_date.split('T')[0]
                : null,
            },
          ].filter((item) => item.kw),
        }}
      />
      <PotetialSolaireSection
        building={buildingPotentialSolar}
        solarAverage={solarAverage}
        solarAverageColor={solarAverageColor}
      />
      {monthlyAverageExposure?.length ? (
        <MonthlyAverageExposure data={monthlyAverageExposure} />
      ) : null}

      {isGeneva && (
        <EnergyHeatConsumptionAround
          data={heatConsumptionAroundData}
          heatConsumptionIndex={building?.heat_consumption_index}
          loading={heatConsumptionAroundLoading}
        />
      )}
    </Box>
  )
}

export default Energy
