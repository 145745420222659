/* eslint-disable max-lines */
import { memo, useState, useMemo, useEffect } from 'react'
import {
  LanguageMenu,
  useAsync,
  useI18n,
  useRouter,
} from '@popety_io/popety-io-lib'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'

import { userSelector, officeSelector } from '../pages/Estimate/redux'
import {
  userSelector as userLandSelector,
  officeSelector as officeLandSelector,
  creationDateSelector,
} from '../pages/LandDetail/redux/summary'
import {
  userSelector as userBuildingSelector,
  officeSelector as officeBuildingSelector,
  creationDateSelector as creationDateBuildingSelector,
} from '../pages/BuildingDetails/redux/summary'
import { Menu, SupportModal } from '../components'
import { APP_VERSION, LIB_VERSION } from '../config'
import { API_BASE_URL } from '../services'
import { getMenus } from './appMenu.utils'

const AppMenu = () => {
  const async = useAsync()
  const [office, setOffice] = useState<any>(null)
  const Estoffice = useSelector(officeSelector)
  const landOffice = useSelector(officeLandSelector)
  const [user, setUser] = useState<any>(null)
  const userEstimate = useSelector(userSelector)
  const userLanding = useSelector(userLandSelector)
  const [createtionDate, setCreationDate] = useState<any>(null)
  const createtionDateLand = useSelector(creationDateSelector)
  const userBuildSelector: any = useSelector(userBuildingSelector)
  const officeBuildiSelector = useSelector(officeBuildingSelector)
  const createtionDateBuilding = useSelector(creationDateBuildingSelector)
  const getPageType = () => {
    const currentUrl = window.location.href

    if (currentUrl.includes('land-detail')) return 'land'
    if (currentUrl.includes('building-detail')) return 'building'

    return 'estimate'
  }

  const { query } = useRouter()

  const [supportModalOpen, setSupportModalOpen] = useState(false)

  const { currentLanguage, setCurrentLanguage } = useI18n()

  const prod = API_BASE_URL?.includes('prod')

  useEffect(() => {
    if (getPageType() === 'land') {
      setUser(userLanding)
      setCreationDate(createtionDateLand)
      setOffice(landOffice)
    }
    if (getPageType() === 'building') {
      setUser(userBuildSelector)
      setCreationDate(createtionDateBuilding)
      setOffice(officeBuildiSelector)
    }
    if (getPageType() === 'estimate') {
      setUser(userEstimate)
      setCreationDate(userEstimate?.creationDate)
      setOffice(Estoffice)
    }
  }, [userEstimate, userLanding, userBuildSelector])

  const version = [APP_VERSION, !prod && LIB_VERSION, !prod && async.data]
    .filter(Boolean)
    .join(' | ')

  const handleLangageChange = (language: string) => {
    setCurrentLanguage(language)
    // TODO: Remove if unused
    // dispatch(updateLanguage(language))
  }

  const handleSupportModal = (state: boolean) => {
    setSupportModalOpen(state)
  }

  const menusData = getMenus()

  const menus = {
    title:
      user?.first_name || user?.last_name
        ? `${user?.first_name} ${user?.last_name}`
        : undefined,
    subTitle: 'subtitle',
    photo: user?.photo,
    homeUrl: '/',
    logo: query?.logo || office?.logo,
    data: menusData,
    creationDate: createtionDate,
    footerMenu: [
      {
        text: (
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ fontSize: '1rem' }}
          >
            {version}
          </Typography>
        ),
      },
    ],
    rightMenus: [
      {
        order: 1,
        component: (
          <Box id="langauge-button">
            <LanguageMenu
              lang={currentLanguage as any}
              onChange={handleLangageChange}
            />
          </Box>
        ),
      },
    ] as any,
  }

  const userPlan: any = useMemo(() => {
    return <div />
  }, [])

  const appMenu = useMemo(() => {
    return (
      <>
        <Menu
          onHelp={() => {
            handleSupportModal(true)
          }}
          {...menus}
        />

        {supportModalOpen && (
          <SupportModal
            user={user}
            open={supportModalOpen}
            handleModal={handleSupportModal}
          />
        )}
      </>
    )
  }, [userPlan, menusData, supportModalOpen])

  return appMenu
}

export default memo(AppMenu)
