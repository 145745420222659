/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  MapLegend,
  useI18n,
  useRouter,
  useMediaQuery,
  Select,
  LayersIcon,
} from '@popety_io/popety-io-lib'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconBtton from '@mui/material/IconButton'

import { updateSunlightOptions, sunlightHoursSelector } from '../../redux'
import SunMapControls from './SunMapControls'

const ColorBox = ({ children, flex = 1 }: BoxProps) => (
  <Box flex={flex} border="1px solid white" textAlign="center">
    {children}
  </Box>
)

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const SunlightExposureLegend = () => {
  const sunlightHours = useSelector(sunlightHoursSelector)

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { updateQuery, query } = useRouter()

  const dispatch = useDispatch()
  const { t } = useI18n()

  const [value, setValue] = useState(0)

  const handleTabChange = (newValue: number) => {
    if (newValue === 0) {
      setValue(newValue)
      updateQuery({ sunLightTab: 'sunlight' })
      dispatch(updateSunlightOptions({ showSunExposure: false }))
    } else if (newValue === 1) {
      setValue(newValue)
      updateQuery({ sunLightTab: 'sunExposer' })
      dispatch(updateSunlightOptions({ showSunExposure: true }))
    } else if (newValue === 2) {
      dispatch(updateSunlightOptions({ showSunExposure: false }))
      setValue(newValue)
      setTimeout(() => {
        updateQuery({ sunLightTab: 'sunExposerGraph' })
      }, 500)
    }
  }

  useEffect(() => {
    if (!isDesktop) {
      updateQuery({ mapLg: undefined })
    }
    updateQuery({ displaySunLegend: true })
  }, [query.mapLg, isDesktop])

  useEffect(() => {
    handleTabChange(1)
  }, [])

  const hours = Math.floor(sunlightHours || 0)
  const partial = sunlightHours - hours

  const backgroundImage =
    'linear-gradient(to right, rgb(0 0 255/ 0.5), rgb(0 255 0 / 0.5), rgb(255 0 0 / 0.5))'

  const handleDisplayLegend = (value: boolean) => {
    updateQuery({ displaySunLegend: value })
  }

  return (
    <MapLegend
      title={t('common.settings')}
      id="sun-hours"
      top="16px"
      right="16px"
      left="auto"
      icon={LayersIcon}
      defaultOpen
      zIndex={999}
      style={{
        width:
          query.mapLg === 'sun-hours'
            ? query.fs === '1'
              ? '33%'
              : '75%'
            : 'auto',
        overflowX: 'hidden',
      }}
    >
      <Box mb="4px">
        <Select
          options={[
            {
              id: 0,
              value: 'sunlight',
              label: t('common.daily_shadow'),
            },
            {
              id: 1,
              value: 'sunExposer',
              label: t('common.daily_sunlight'),
            },
            {
              id: 2,
              value: 'sunExposerGraph',
              label: t('common.annual_sunlight'),
            },
          ]}
          value={query.sunLightTab || 'sunlight'}
          onChange={(e: any) => handleTabChange(e?.id)}
          sx={{
            width: '100%',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant="caption"
            sx={{
              color: '#969696',
              fontSize: '0.9rem',
              fontWeight: 500,
              lineHeight: 1.66,
            }}
          >
            LEGENDE
          </Typography>
        </Box>
        <Box>
          {(!query.displaySunLegend || query.displaySunLegend === 'false') && (
            <IconBtton onClick={() => handleDisplayLegend(true)}>
              <VisibilityIcon fontSize="small" />
            </IconBtton>
          )}
          {query.displaySunLegend && query.displaySunLegend === 'true' && (
            <IconBtton onClick={() => handleDisplayLegend(false)}>
              <VisibilityOffIcon fontSize="small" />
            </IconBtton>
          )}
        </Box>
      </Box>
      {query.displaySunLegend && query.displaySunLegend === 'true' && (
        <Box sx={{ width: '100%' }}>
          <CustomTabPanel value={value} index={0}>
            <SunMapControls />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box
              display="flex"
              height="20px"
              minWidth={300}
              sx={{ backgroundImage }}
              className="print-legend"
            >
              {Array.from(Array(hours).keys()).map((h) => (
                <ColorBox key={h}>{h + 1}</ColorBox>
              ))}
              <ColorBox flex={partial} />
            </Box>
            <SunMapControls />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <span id="tooltip" />
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                flexWrap: 'wrap',
                zIndex: 2000,
              }}
            >
              <div id="output" />
              <button id="download">{t('land.sun.download')}</button>
            </div>
            <canvas className="loading" id="canvas" width="0" height="0" />
          </CustomTabPanel>
        </Box>
      )}
    </MapLegend>
  )
}

export default SunlightExposureLegend
