import Box from '@mui/material/Box'
import { Map, useRouter, useI18n } from '@popety_io/popety-io-lib'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { showSunExposureSelector } from '../../redux/sunlight'

type SunExposureTooltipProps = {
  map: Map
  shadeMap: any
}

const SunExposureTooltip = ({ map, shadeMap }: SunExposureTooltipProps) => {
  const { query } = useRouter()
  const showSunExposure = useSelector(showSunExposureSelector)

  const { t } = useI18n()

  const isSunlightTab = query.tab === 'sunlight' || query.subTab === 'sunlight'

  const [hoverFeature, setHoverFeature] = useState<any>()

  const handleMouseMove = (e: any) => {
    setHoverFeature(e)
  }

  const handleMouseLeave = () => {
    setHoverFeature(undefined)
    map.getCanvas().style.cursor = ''
  }

  useEffect(() => {
    setHoverFeature(undefined)

    if (!isSunlightTab || !map) return

    map.on('mousemove', handleMouseMove)
    map.on('mouseleave', handleMouseLeave)

    if (!showSunExposure) {
      map.off('mousemove', handleMouseMove)
      map.off('mouseleave', handleMouseLeave)
    }

    return () => {
      map.off('mousemove', handleMouseMove)
      map.off('mouseleave', handleMouseLeave)
    }
  }, [map, showSunExposure, isSunlightTab])

  const hoursOfSun =
    hoverFeature &&
    shadeMap?.getHoursOfSun(hoverFeature.point.x, hoverFeature.point.y)

  return (
    <>
      {hoverFeature && (
        <Box
          position="absolute"
          left={hoverFeature.point?.x}
          top={hoverFeature.point?.y}
          zIndex={1000}
          bgcolor="rgba(97, 97, 97, 0.92)"
          color="white"
          p="1px 6px"
          borderRadius={2}
          m={1}
        >
          {`${Math.floor(hoursOfSun || 0)} ${t('land.sun.sunHours')}`}
        </Box>
      )}
    </>
  )
}

export default SunExposureTooltip
