import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined'
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway'
import TrainIcon from '@mui/icons-material/Train'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import { Box, Skeleton, Typography } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { noiseSelector } from '../../../redux/summary'

import { noiseBuildingSelector } from '../../../../BuildingDetails'
import { getNoiseColor } from '../utils/getNoiseColor'
import { ContentTitleItemRoot } from './NightAndDay.style'

const NightAndDay = ({ type }: any) => {
  const { t } = useI18n()
  const landNoise: any = useSelector(noiseSelector)
  const buildingNoise: any = useSelector(noiseBuildingSelector)
  const noise = type === 'land' ? landNoise : buildingNoise
  const loading = false

  const data = [
    {
      icon: (
        <WbSunnyIcon
          fontSize="large"
          sx={{
            color: '#FBD823',
          }}
        />
      ),
      title: t('common.jour'),
      maxNoiseTitle: t('estimate.Niveau bruit maximum jour'),
      dailyTrafic: noise?.noiseRoadDay || '0',
      railway: noise?.noiseRailwayDay || '0',
      maxNoise: Math.max(noise?.noiseRoadDay, noise?.noiseRailwayDay) || '0',
    },
    {
      icon: <BedtimeOutlinedIcon fontSize="large" />,
      title: t('common.night'),
      maxNoiseTitle: t('estimate.Niveau bruit maximum nuit'),
      dailyTrafic: noise?.noiseRoadNight || '0',
      railway: noise?.noiseRailwayNight || '0',
      maxNoise:
        Math.max(noise?.noiseRoadNight, noise?.noiseRailwayNight) || '0',
    },
  ]

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {data.map((item, key) => {
        return (
          <Box
            key={`noise_${key}`}
            display="flex"
            flexWrap="wrap"
            flexDirection="row"
            justifyContent="center"
            gap={6}
            fontWeight={500}
          >
            <Box display="flex" flexDirection="column" gap="16px">
              <Box display="flex" alignItems="center" gap={1}>
                {item.icon}
                <span style={{ textTransform: 'uppercase' }}>{item.title}</span>
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  color="#969696"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap={1}>
                    <TrainIcon
                      sx={{
                        fontSize: '18px',
                      }}
                    />
                    <span>{t('common.trafic_routier')}</span>
                  </Box>
                  {!loading ? (
                    <span style={{ color: getNoiseColor(item.dailyTrafic) }}>
                      {item.dailyTrafic === '-'
                        ? '-'
                        : `${item.dailyTrafic} dB`}
                    </span>
                  ) : (
                    <Skeleton variant="text" width={50} />
                  )}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  color="#969696"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap={1}>
                    <DirectionsRailwayIcon
                      sx={{
                        fontSize: '18px',
                      }}
                    />
                    <span>{t('common.ferroviaire')}</span>
                  </Box>
                  {!loading ? (
                    <span style={{ color: getNoiseColor(item.railway) }}>
                      {item.railway === '-' ? '-' : `${item.railway} dB`}
                    </span>
                  ) : (
                    <Skeleton variant="text" width={50} />
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ContentTitleItemRoot>
                {!loading ? (
                  <Typography
                    variant="h4"
                    fontWeight={500}
                    color={getNoiseColor(item.maxNoise)}
                  >
                    {item.maxNoise} dB
                  </Typography>
                ) : (
                  <Skeleton variant="text" width={50} />
                )}
                <Typography display="flex" alignItems="center">
                  {item.maxNoiseTitle}
                </Typography>
              </ContentTitleItemRoot>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default NightAndDay
