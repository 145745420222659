/* eslint-disable import/no-cycle */
import { Fragment, memo } from 'react'

import { SummaryMapConfig } from '../Summary'

import { BuildingHistoryMapConfig } from '../components/BuildingHistory'
import { LandSummaryMapConfig } from '../LandSummary'
import { EnergyMapConfig } from '../Energy'
import { LivabilityMapConfig } from '../../LandDetail/Livability'
import { SunlightMapConfig } from '../../LandDetail/Sunlight'
import { NoiseMapConfig } from '../../LandDetail/LandNoise'
import { LandEventHistoryMapConfig as BuildingEventHistoryMapConfig } from '../../LandDetail/LandEventHistory'

const MapConfigByPage: any = {
  home: BuildingHistoryMapConfig,
  summary: SummaryMapConfig,
  parcelle: LandSummaryMapConfig,
  energy: EnergyMapConfig,
  livability: LivabilityMapConfig,
  sunlight: SunlightMapConfig,
  noise: NoiseMapConfig,
  history: BuildingEventHistoryMapConfig,
}

/**
 * Map controllers have the full control of the map
 */
const MapControllers = memo((props: any) => (
  <>
    {Object.values(MapConfigByPage).map((comp: any, i) => {
      const { Controller } = comp

      if (!Controller) return <Fragment key={i} />

      return <Controller {...props} key={i} />
    })}
  </>
))

export { MapConfigByPage, MapControllers }
