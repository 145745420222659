/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import summaryService from './summaryService'

export const summaryMapLayers = [
  'land',
  'authorizedLimit',
  'restrictions',
  'buildings',
]

const initialState = {
  loading: false,
  error: false,
  landsValue: {},
  selectedLayers: summaryMapLayers,
  cityGeoCenter: {},
  cityGeojson: {},
  user: {},
  company: {},
  theme: null,
  transactionDetail: {},
  office: {},
  creationDate: null,
  selectedAnaylyst: {},
}

export const getLandSummary = createAsyncThunk(
  'summary/getLandSummary',
  summaryService.getLandSummary,
)

export const getPublicFile = createAsyncThunk(
  'files/getPublicFile',
  summaryService.getPublicFile,
)

const landSummary = createSlice({
  name: 'landSummary',
  initialState,
  reducers: {
    updateTheme(state, action) {
      if (action.payload.primary || action.payload.secondary) {
        state.theme = {
          primary: action.payload.primary ? `#${action.payload.primary}` : null,
          secondary: action.payload.secondary
            ? `#${action.payload.secondary}`
            : null,
        }
      }
    },
    updateSelectedAnalyst(state, action) {
      state.selectedAnaylyst = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandSummary.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getLandSummary.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (action?.payload?.errors?.length > 0) {
          state.loading = false
          state.error = true
          window.location.href = '/link-expired'

          return
        }

        state.loading = false
        state.error = false
        state.creationDate = action.payload.data.update_timestamp
        state.landsValue = action.payload
        state.company = data?.company
        state.office = data?.office
        if (action.payload?.data?.value?.selectedAnalysis) {
          state.selectedAnaylyst = action.payload?.data?.value?.selectedAnalysis
        }
      })
      .addCase(getLandSummary.rejected, (state) => {
        state.loading = false
        state.error = true
        window.location.href = '/link-expired'
      })
  },
})

export const landSummaryReducer = {
  landSummary: landSummary.reducer,
}

export const { updateTheme, updateSelectedAnalyst } = landSummary.actions
