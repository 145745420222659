import { createSelector } from '@reduxjs/toolkit'
import { getLivabilityIsochroneLayers } from './pois.layers'

const poisStateSelector = (state: any) => state.pois

const displayPoisSelector = (state: any) => state.pois?.display

const transportTypeSelector = (state: any) => state.pois?.transportType

const poiIsochroneSelector = (state: any) => state.pois?.isochrone || {}

const poisIsochroneLayersSelector = createSelector(
  poiIsochroneSelector,
  getLivabilityIsochroneLayers,
)

export {
  poisStateSelector,
  displayPoisSelector,
  transportTypeSelector,
  poisIsochroneLayersSelector,
}
