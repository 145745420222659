/* eslint-disable max-lines */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSunTimes } from '../LandDetail/Sunlight/SunExposure'
import { updateSunlightOptions } from '../LandDetail/redux/sunlight'
import { buildingSelector, landsSelector, sunDateSelector } from './redux'

const useGetBuildingDetailsData = () => {
  const dispatch: any = useDispatch()

  const building = useSelector(buildingSelector)
  const lands = useSelector(landsSelector)

  const sunDate = useSelector(sunDateSelector)

  useEffect(() => {
    const sunlightTimes = getSunTimes({
      sunDate,
      center: {
        lng: building?.geo_center?.lon,
        lat: building?.geo_center?.lat,
      },
    })

    const sunlightSummerTimes = getSunTimes({
      sunDate,
      center: {
        lng: building?.geo_center?.lon,
        lat: building?.geo_center?.lat,
      },
      month: 5,
      day: 21,
    })

    const sunlightWinterTimes = getSunTimes({
      sunDate,
      center: {
        lng: building?.geo_center?.lon,
        lat: building?.geo_center?.lat,
      },
      month: 11,
      day: 21,
    })

    dispatch(
      updateSunlightOptions({
        sunlightTimes,
        sunlightWinterTimes,
        sunlightSummerTimes,
      }),
    )
  }, [building])

  useEffect(() => {
    if (!lands || lands.length === 0) return

    let buildings: any[] = []

    lands.forEach((land: any) => {
      const building = land?.buildings || []

      buildings = [...buildings, ...building]
    })
  }, [lands])
}

export default useGetBuildingDetailsData
