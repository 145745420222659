import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const EstimateMenu = lazy(
  () => import('../pages/Estimate/components/EstimateMenu'),
)

const LandMenu = lazy(() => import('../pages/LandDetail/components/Menu'))
const BuildingMenu = lazy(
  () => import('../pages/BuildingDetails/components/BuildingDetailsMenu'),
)

const SideBox = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<EstimateMenu />} />
        <Route path="/:lang" element={<EstimateMenu />} />
        <Route path="/land-detail/:lang" element={<LandMenu />} />
        <Route path="/building-detail/:lang" element={<BuildingMenu />} />
        <Route path="*" element={null} />
      </Routes>
    </Suspense>
  )
}

export default memo(SideBox)
