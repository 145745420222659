import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getBuildingSummary = ({ hash }: any) => {
  return client.get(`/public/building?hash=${hash}`)
}

const summaryService = {
  getBuildingSummary,
}

export default summaryService
