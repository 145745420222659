import { buildingConfigurationReducer } from './configuration'
import { energyReducer } from './energy'
import { landSummaryReducer } from './landSummary'
import { buildingSummaryReducer } from './summary'

export * from './summary'

export const buildingDetailsReducer = {
  ...buildingSummaryReducer,
  ...landSummaryReducer,
  ...energyReducer,
  ...buildingConfigurationReducer,
}
