import { estimateSummaryReducer } from './summary'
import { basemapReducer } from './map'
import { poisReducer } from './pois'
import { sunlightReducer } from './sunlight'

export * from './summary'
export * from './map'
export * from './sunlight'
export * from './pois'

export const estimateReducer = {
  ...estimateSummaryReducer,
  ...basemapReducer,
  ...poisReducer,
  ...sunlightReducer,
}
