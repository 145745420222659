/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box'
import { LeftMenu, useI18n, useRouter } from '@popety_io/popety-io-lib'

import { getBuildingDetailsMenuData } from './BuildingDetailsMenu.data'

const BuildingDetailsMenu = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const menus = getBuildingDetailsMenuData(t)

  const data = menus.map(({ ...menu }, i: number) => ({
    ...menu,
    active: !query.tab ? i === 0 : query.tab === menu.key,
    onClick: () => {
      updateQuery({
        tab: menu.key,
        subTab: null,
        fs: null,
        transactionCenter: null,
        title: null,
        faoCenter: null,
        transactionOpened: null,
        faoOpened: null,
        listingOpened: null,
        listingCenter: null,
        listingId: null,
        transactionId: null,
        faoId: null,
      })
      if (menu?.subMenus?.length) {
        updateQuery({
          subTab: menu.subMenus[0].key,
        })
      }
    },
    subMenus: menu.subMenus?.map((subMenu: any) => ({
      ...subMenu,
      active: query?.subTab === subMenu?.key,
      onClick: () => {
        updateQuery({
          tab: menu.key,
          subTab: subMenu.key,
          fs: null,
          transactionCenter: null,
          title: null,
          faoCenter: null,
          transactionOpened: null,
          faoOpened: null,
          listingOpened: null,
          listingCenter: null,
        })
      },
    })),
  }))

  return (
    <Box width={107} height="100%">
      <LeftMenu data={data as any} />
    </Box>
  )
}

export default BuildingDetailsMenu
