/* eslint-disable max-lines */

import { createSelector } from '@reduxjs/toolkit'

const buildingLoadingSelector = (state: any) => state?.buildingSummary?.loading
const buildingErrorSelector = (state: any) => state?.buildingSummary?.error
const summarySelector = (state: any) => state?.buildingSummary?.buildingValue
const creationDateSelector = (state: any) =>
  state?.buildingSummary?.creationDate

const sectionsSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.sections,
)
const buildingSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.building,
)
const landsSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.lands,
)
const sunDateSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.sunDate,
)
const buildingIdStrSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.buildingIdStr,
)
const userSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.user,
)
const officeSelector = (state: any) => state?.buildingSummary?.office
const regionSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.region,
)
const regionsByNameSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.regionsByName,
)
const geoPolygonSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.geoPolygon,
)
const livabilityIsochroneSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.livabilityIsochrone,
)
const roofPolygonSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.roofPolygon,
)
const configurationSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.configuration,
)
const buildingAnalysisHistorySelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.buildingAnalysisHistory,
)
const rawTransactionsSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.rawTransactions,
)
const faosSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.faos,
)
const listingsSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.listings,
)
const estimateEditedSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.estimateEdited,
)
const monthlyAverageExposureSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.monthlyAverageExposure,
)
const buildingPotentialSolarDataSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.buildingPotentialSolar,
)
const activeUniqueBuildingTagsSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.activeUniqueBuildingTags,
)
const userDetailedDataSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.userDetailed,
)
const heatConsumptionAroundSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.heatConsumptionAround,
)
const actionHistoriesBuildingSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.actionHistories,
)
const filesBuildingSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.files,
)
const poisNumberSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.poisNumber,
)
const groupedBuildingPoiCategoriesSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.groupedBuildingPoiCategories,
)
const poiBuildingCategorySelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.poiBuildingCategory,
)
const noiseBuildingSelector = createSelector(
  summarySelector,
  (summary) => summary?.data?.value?.noiseBuilding,
)

export {
  buildingLoadingSelector,
  buildingErrorSelector,
  summarySelector,
  buildingSelector,
  landsSelector,
  sunDateSelector,
  buildingIdStrSelector,
  userSelector,
  regionSelector,
  regionsByNameSelector,
  geoPolygonSelector,
  livabilityIsochroneSelector,
  roofPolygonSelector,
  configurationSelector,
  buildingAnalysisHistorySelector,
  rawTransactionsSelector,
  faosSelector,
  listingsSelector,
  estimateEditedSelector,
  monthlyAverageExposureSelector,
  buildingPotentialSolarDataSelector,
  activeUniqueBuildingTagsSelector,
  sectionsSelector,
  userDetailedDataSelector,
  heatConsumptionAroundSelector,
  officeSelector,
  creationDateSelector,
  actionHistoriesBuildingSelector,
  filesBuildingSelector,
  poisNumberSelector,
  groupedBuildingPoiCategoriesSelector,
  poiBuildingCategorySelector,
  noiseBuildingSelector,
}
