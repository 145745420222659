import { lazy } from 'react'

// ESTIMATE
const Estimate = lazy(() => import('../pages/Estimate'))

// LandDetails

const LandDetails = lazy(() => import('../pages/LandDetail'))

// BuildingDetails

const BuildingDetails = lazy(() => import('../pages/BuildingDetails'))

// Error
const Error = lazy(() => import('../pages/Error'))

/**
 * navigations
 */
const navigations = [
  { path: '/', private: true, estimate: true, page: <Estimate /> },
  {
    path: '/land-detail/:lang',
    private: false,
    estimate: false,
    page: <LandDetails />,
  },
  {
    path: '/building-detail/:lang',
    private: false,
    estimate: false,
    page: <BuildingDetails />,
  },
  { path: '/:lang', private: true, estimate: true, page: <Estimate /> },
  { path: '/link-expired', private: true, estimate: true, page: <Error /> },
  { path: '*', page: <Error /> },
]

export { navigations }
