/* eslint-disable max-lines */

import * as turf from '@turf/turf'

interface GeoJSONPolygon {
  type: 'Polygon' | 'MultiPolygon'
  coordinates: number[][][] | number[][][][]
}
const getHistoryBounds = (history: any) => {
  const padding = 0.2 // Adjust this value according to your needs

  const bboxHistory = history
    ?.map((element: any) => {
      return (
        element?.default_value?.geo_center ||
        element?.value?.geoCenter ||
        element?.geo_center
      )
    })
    ?.filter(Boolean)
    ?.map((element: any) => [element?.lon, element?.lat])

  const featureColletion = bboxHistory?.length
    ? turf.featureCollection(
        bboxHistory?.map((element: any) => turf.point(element)) as any,
      )
    : null

  const envelope = featureColletion ? turf.envelope(featureColletion) : null

  const paddedBounds = envelope?.bbox?.map((element: any, index: number) => {
    return index < 2 ? element - padding : element + padding
  })

  return paddedBounds
}

const getDasharrayLayer = (id: string, geoPolygon: GeoJSONPolygon) => {
  return [
    geoPolygon?.type && {
      id,
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [{ type: 'feature', properties: {}, geometry: geoPolygon }],
      },
      layers: [
        {
          id: 'locationPolygonLayer',
          type: 'line',
          paint: {
            'line-color': '#00ade6',
            'line-width': 3,
            'line-dasharray': [3, 1],
          },
        },
      ],
    },
  ].filter(Boolean)
}

const getPlotRadius = (
  geoCenter: { lon: number; lat: number },
  radius = 500,
) => {
  if (!geoCenter?.lon && !geoCenter?.lat) return null

  const numberRadius = Number(radius) / 1000
  const center = [Number(geoCenter?.lon), Number(geoCenter?.lat)].filter(
    Boolean,
  )

  if (!center?.length) {
    return null
  }

  const options = { steps: 64, units: 'kilometers', properties: {} }
  const circle = turf.circle(center, numberRadius, options as any)

  return [
    {
      id: 'radius',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [circle] },
      layers: [
        {
          id: 'radiusLayer',
          type: 'line',
          paint: {
            'line-color': '#00ade6',
            'line-width': 3,
            'line-dasharray': [3, 1],
          },
        },
      ],
    },
  ]
}

const getPlotLocation = (geoPolygon: any) =>
  [
    geoPolygon?.type && {
      id: 'locationPolygon',
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [{ type: 'feature', properties: {}, geometry: geoPolygon }],
      },
      layers: [
        {
          id: 'locationPolygonLayer',
          type: 'line',
          paint: {
            'line-color': '#00ade6',
            'line-width': 3,
            'line-dasharray': [3, 1],
          },
        },
      ],
    },
  ].filter(Boolean)

const getPlotDashLocation = (geoPolygon: any) => {
  return [
    geoPolygon?.type && {
      id: 'locationPolygon',
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [{ type: 'feature', properties: {}, geometry: geoPolygon }],
      },
      layers: [
        {
          id: 'locationPolygonLayer',
          type: 'line',
          paint: {
            'line-color': '#00ade6',
            'line-width': 3,
            'line-dasharray': [4, 2],
          },
        },
      ],
    },
  ].filter(Boolean)
}

const getBbox = (
  data: any,
  query: Record<string, any>,
  geoCenter?: number[],
) => {
  if (
    query?.tab === 'transaction' ||
    query?.tab === 'fao' ||
    query?.tab === 'listing'
  ) {
    const geometry = data?.[1]?.data?.features?.[0]?.geometry

    if (!geometry) return

    const scaledPoly = turf.transformScale(geometry, 1.5)

    const bbox = turf.bbox(scaledPoly)

    return bbox
  }

  const radius =
    query?.tab === 'livability' || query?.subTab === 'livability'
      ? (15000 + 0.4) / 1000
      : 0.5

  const options = { steps: 64, units: 'kilometers', properties: {} }
  const circle = turf.circle(geoCenter as any, radius, options as any)

  if (!circle) return

  const bbox = turf.bbox(circle?.geometry)

  return bbox
}

export {
  getBbox,
  getDasharrayLayer,
  getPlotDashLocation,
  getPlotLocation,
  getPlotRadius,
  getHistoryBounds,
}
