import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const EstimateHeader = lazy(
  () => import('../pages/Estimate/components/EstimateHeader'),
)

const LandDetailsHeader = lazy(
  () => import('../pages/LandDetail/components/Header'),
)

const BuildingDetailsHeader = lazy(
  () => import('../pages/BuildingDetails/components/BuildingDetailsHeader'),
)

const LayoutHeaderLeft = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<EstimateHeader />} />
        <Route path="/link-expired" element={null} />
        <Route path="/:lang" element={<EstimateHeader />} />
        <Route path="*" element={null} />
        <Route path="/land-detail/:lang" element={<LandDetailsHeader />} />
        <Route
          path="/building-detail/:lang"
          element={<BuildingDetailsHeader />}
        />
      </Routes>
    </Suspense>
  )
}

export default memo(LayoutHeaderLeft)
