/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  formatPrice,
  toReadableNumber,
  useI18n,
  StatusContainer,
  TypeIcon,
  PieceIcon,
  LivingSpaceIcon,
  ConstructionYearIcon,
  ConfigurationIcon,
} from '@popety_io/popety-io-lib'

const ListingHistoryCard = ({ listing, onClick }: any) => {
  const { t } = useI18n()
  const theme = useTheme()

  const chips = [
    {
      label: listing.property_type,
      value: listing.property_type,
      key: 'property_type',
      icon: (
        <TypeIcon
          sx={{
            fontSize: '1.2rem',
          }}
        />
      ),
    },
    {
      label: `${t('listing.rooms', { count: listing.rooms_nb })}`,
      value: listing.rooms_nb,
      key: 'rooms_nb',
      icon: (
        <PieceIcon
          sx={{
            fontSize: '1.2rem',
          }}
        />
      ),
      endAdornment: t('common.rooms'),
    },
    {
      label: `${toReadableNumber(listing?.area || 0)} m2`,
      value: listing.area,
      key: 'area',
      icon: (
        <LivingSpaceIcon
          sx={{
            fontSize: '1.2rem',
          }}
        />
      ),
      endAdornment: 'm2',
    },
    {
      label: listing.YearOfConstruction,
      value: listing.YearOfConstruction,
      key: 'YearOfConstruction',
      icon: (
        <ConstructionYearIcon
          sx={{
            fontSize: '1.2rem',
          }}
        />
      ),
    },
    {
      label: listing.Floor,
      value: listing.Floor,
      key: 'Floor',
      icon: (
        <ConfigurationIcon
          sx={{
            fontSize: '1.2rem',
          }}
        />
      ),
    },
    {
      label: `${toReadableNumber(listing?.UsefulArea || 0)} m2`,
      value: listing.UsefulArea,
      key: 'UsefulArea',
      icon: (
        <LivingSpaceIcon
          sx={{
            fontSize: '1.2rem',
          }}
        />
      ),
    },
  ].filter(
    (i) =>
      typeof i.value === 'string' ||
      (typeof i.value === 'number' && i.value > 0),
  )

  const calculateDate = (date2: any) => {
    try {
      const newdate1: any = new Date(Date.now())
      const newdate2: any = new Date(date2)

      const differenceInMilliseconds = Math.abs(newdate2 - newdate1)

      const differenceInDays = Math.floor(
        differenceInMilliseconds / (24 * 60 * 60 * 1000),
      )

      return differenceInDays
    } catch (e) {
      return 0
    }
  }

  const listingDuration =
    listing?.listing_duration === 0 ? '0' : listing?.listing_duration

  const translatedListingDuration =
    listing?.ad_status && listing.listing_timestamp
      ? t(
          calculateDate(listing.listing_timestamp) > 1
            ? 'listing.daysAgo'
            : 'listing.dayAgo',
          {
            days: calculateDate(listing.listing_timestamp),
          },
        )
      : !listing?.ad_status && listingDuration
        ? t(listingDuration > 1 ? 'listing.daysAgo' : 'listing.dayAgo', {
            days: listingDuration,
          })
        : 'N/A'

  return (
    <Box
      sx={{
        padding: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        width: '100%',
        flexWrap: 'wrap',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: { xs: '100%', lg: '20%' },
        }}
      >
        <Typography
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          fontWeight="500"
          fontSize="1.1rem"
        >
          {listing?.deal_type === 'rent'
            ? t('common.Annonce de location')
            : t('common.Annonce de vente')}
        </Typography>
        {listing?.price ? (
          <Typography
            fontSize="1.1rem"
            fontWeight={500}
            color={theme?.palette?.primary?.main || '#00ADE6'}
          >
            {formatPrice(listing.price, 0)}
          </Typography>
        ) : null}
        {listing.price_per_square_meter ? (
          <Typography fontSize="0.9rem" color="#969696">
            {formatPrice(listing.price_per_square_meter, 0)}/m2
          </Typography>
        ) : null}
      </Box>

      <Box
        sx={{
          width: { xs: '100%', lg: '60%' },
          paddingLeft: { xs: '0', lg: '10px' },
          paddingTop: { xs: '10px', lg: '0' },
        }}
      >
        <Box display="flex" flexDirection="column" justifyContent="left">
          <Typography
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {listing.title}
          </Typography>
          {!!chips?.length && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {chips.map((chip: any, i: number) => (
                <Box
                  key={i}
                  sx={{
                    width: '48%',
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Box>{chip.icon}</Box>
                  <Typography fontSize="1rem">
                    {chip.value} {chip?.endAdornment ? chip.endAdornment : ''}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: '100%', lg: '20%' },
          paddingLeft: { xs: '0', lg: '10px' },
          paddingTop: { xs: '10px', lg: '0' },
        }}
      >
        <Box>
          <Box display="flex">
            <StatusContainer>
              {listing?.ad_status ? (
                <span className="status active">
                  {t('land.listing.status.active')}
                </span>
              ) : (
                <span className="status expired">
                  {t('land.listing.status.expired')}
                </span>
              )}
            </StatusContainer>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Typography component="i" color="rgb(141, 155, 163)">
              {t('land.listing.online')}
            </Typography>
            <Typography component="i" color="rgb(141, 155, 163)">
              <span style={{ color: '#000000' }}>{' : '}</span>
            </Typography>
            <Typography component="i" color="rgb(141, 155, 163)">
              <span style={{ color: '#000000' }}>
                {translatedListingDuration}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ListingHistoryCard
