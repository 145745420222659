import Box from '@mui/material/Box'

import EstimateLivabilityHeader from './EstimateLivabilityHeader'
import LivabilityDescription from './LivabilityDescription'
import LivabilityHeader from './LivabilityHeader'
import LivabilitySkeleton from './LivabilitySkeleton'

const Livability = ({
  isEstimate,
  type,
}: {
  isEstimate?: boolean
  type: string
}) => {
  const livabilityLoading = false

  return (
    <Box>
      {!isEstimate ? (
        <LivabilityHeader type={type} />
      ) : (
        <EstimateLivabilityHeader />
      )}

      <Box
        sx={{
          width: '100%',
        }}
      >
        {livabilityLoading &&
          [1, 2, 3].map((i) => <LivabilitySkeleton key={i} />)}
      </Box>
      <Box>
        <LivabilityDescription type={type} />
      </Box>
    </Box>
  )
}

export default Livability
