import Description from './Description'
import Information from './Information'
import Header from './Header'
import MainInformation from './MainInformation'
import Details from './Details'

const EstimateTransactionDetailBody = ({
  transaction,
  children,
  transactionsIds,
}: {
  transaction: any
  children?: any
  transactionsIds: any
}) => (
  <>
    <Header transaction={transaction} transactionsIds={transactionsIds} />
    {children}
    <MainInformation transaction={transaction} />
    <Details transaction={transaction} />
    <Description transaction={transaction} />
    <Information transaction={transaction} />
  </>
)

export default EstimateTransactionDetailBody
