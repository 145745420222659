/* eslint-disable max-lines */
import { useSelector } from 'react-redux'
import React, { FC, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Typography, TextField, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import { Button, useI18n } from '@popety_io/popety-io-lib'
import Tooltip from '@mui/material/Tooltip'
import { estimateEditedSelector } from '../redux'
import { ValueField } from '../../../components/ValueField'

interface IProp {
  type?: string
  onSaveChange?: (values: any) => void
  data: any
  toggleDisplay?: (name: string) => (checked: boolean) => void
  defaultShow?: boolean
}

const CurrentSolaireInstalationSection: FC<IProp> = ({
  data,
  type,
  onSaveChange,
  toggleDisplay,
  defaultShow = true,
}) => {
  const { t } = useI18n()
  const [values, setValues] = useState<any>({
    totalPower: data.totalPower,
    instalationDate: data.instalationDate || [],
  })
  const [editHeating, setEditHeating] = useState(false)
  const [show, setShow] = useState(defaultShow)
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const isLoading = false

  useEffect(() => {
    setShow(defaultShow)
  }, [defaultShow])

  useEffect(() => {
    setValues({
      totalPower:
        estimateEdited?.totalPower ||
        data.instalationDate
          ?.map((i: any) => Number(i.kw))
          ?.reduce((a: any, b: any) => a + b, 0) ||
        0,
      instalationDate: data.instalationDate || [],
    })
  }, [data])

  const defaultData = {
    totalPower: '',
    instalationDate: data.instalationDate || [],
  }

  const handleEditHeating = () => {
    setEditHeating(!editHeating)
  }

  const handleSave = () => {
    onSaveChange?.(values)

    setEditHeating(false)
  }

  const onChange = (key: string) => (value: any) => {
    setValues({
      ...values,
      [key]: value,
    })
  }

  const handleCancel = () => {
    setValues({
      totalPower:
        data.totalPower ||
        data.instalationDate
          ?.map((i: any) => Number(i.kw))
          ?.reduce((a: any, b: any) => a + b, 0) ||
        0,
      instalationDate: data.instalationDate || [],
    })

    setEditHeating(false)
  }

  const onToggle = (checked: boolean) => {
    toggleDisplay?.('currentSolaireInstalation')(checked)
  }

  useEffect(() => {
    toggleDisplay?.('currentSolaireInstalation')(defaultShow)
  }, [])

  const handleAddDate = () => {
    const newDate = {
      date: '',
      kw: '',
    }

    setValues({
      ...values,
      instalationDate: [...values.instalationDate, newDate],
    })
  }

  const handleChangeDate = (index: number) => (key: string) => (value: any) => {
    let newDate = values.instalationDate

    newDate = newDate?.map((date: any, idx: number) => {
      if (idx === index) {
        return {
          ...date,
          [`${key}`]: value,
        }
      }

      return date
    })

    setValues({
      ...values,
      instalationDate: newDate,
    })
  }

  const handleDelete = (index: number) => {
    const newDate = [...values.instalationDate]

    newDate.splice(index, 1)

    const updatedValues = {
      ...values,
      instalationDate: newDate,
    }

    setValues({
      ...updatedValues,
    })
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBlock: '16px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          {t('common.Installation solaire actuelle')}
        </Typography>
        {type === 'estimate' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!editHeating && show && (
              <IconButton
                onClick={handleEditHeating}
                disabled={isLoading}
                sx={{ fill: '#969696', cursor: 'pointer' }}
              >
                <EditIcon />
              </IconButton>
            )}

            {editHeating && show && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <IconButton
                  onClick={handleSave}
                  disabled={isLoading}
                  sx={{ fill: '#969696', cursor: 'pointer', marginRight: 2 }}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  onClick={handleCancel}
                  disabled={isLoading}
                  sx={{ fill: '#969696', cursor: 'pointer', marginRight: 2 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}

            {type === 'estimate' && (
              <Tooltip
                title={
                  show
                    ? t('common.disable_button_tooltip')
                    : t('common.activate')
                }
              >
                <Switch
                  checked={show}
                  onChange={(e) => onToggle?.(e.target.checked)}
                  size="small"
                  disabled={isLoading}
                />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
      {show && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 'fit-content',
                maxWidth: '120px',
                textAlign: 'center',
              }}
            >
              <ValueField
                value={values?.totalPower}
                valueDefault={defaultData?.totalPower}
                onChangeValue={(v) => onChange('totalPower')(v)}
                isEdit={editHeating}
                endAdornment={values?.totalPower ? 'kW' : ''}
                onReset={() => onChange('totalPower')(defaultData?.totalPower)}
                sx={{
                  display: 'flex',
                  fontSize: '1.70rem',
                  color: '#000000',
                  fontWeight: 600,
                }}
              />
              <Typography>{t('estimate.Puissance totale')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                fontWeight="500"
                sx={{
                  color: '#969696',
                }}
              >
                {t('estimate.Date installation')}:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: editHeating ? '-5px' : '0px',
                  gap: editHeating ? '10px' : '0px',
                }}
              >
                {values?.instalationDate?.length ? (
                  values?.instalationDate?.map((item: any, index: number) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                      }}
                    >
                      {editHeating ? (
                        <TextField
                          id="date"
                          label={t('common.Start subscription')}
                          name="start_date"
                          value={item?.date?.substring(0, 10)}
                          onChange={(e) =>
                            handleChangeDate(index)('date')(e.target.value)
                          }
                          type="date"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography fontWeight="500" key={index}>
                          {item.date || ''}
                        </Typography>
                      )}
                      <ValueField
                        valueDefault={item?.kw || 0}
                        value={item?.kw || 0}
                        endAdornment={item?.kw ? 'kW)' : ''}
                        startAdornment={item?.kw ? '(' : ''}
                        onChangeValue={(v) => handleChangeDate(index)('kw')(v)}
                        isEdit={editHeating}
                      />
                      {editHeating && (
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  ))
                ) : (
                  <span>-</span>
                )}
                {editHeating && (
                  <Button
                    onClick={handleAddDate}
                    {...{ component: 'button' }}
                    size="small"
                  >
                    + {t('estimate.Ajouter une date')}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default CurrentSolaireInstalationSection
