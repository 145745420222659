import { Box, Typography, useTheme } from '@mui/material'
import { useI18n, useSize } from '@popety_io/popety-io-lib'
import { BarChart, YAxis, XAxis, Bar, Tooltip } from 'recharts'
import { ChartContainer, CustomTooltip } from './MonthlyAverageExposure.style'

const CustomTooltipExposure = ({ active, payload, label }: any) => {
  if (!active || !payload?.length) return null

  return (
    <CustomTooltip>
      <p style={{ fontSize: '0.9rem' }}>{label}</p>
      <p style={{ color: '#00ade6', fontSize: '0.8rem' }}>
        {payload[0].value} kWh/m2
      </p>
    </CustomTooltip>
  )
}

const MonthlyAverageExposure = ({ data }: any) => {
  const { t } = useI18n()
  const { ref, width = 300 } = useSize()
  const theme = useTheme()

  const dataTranslate = data.map((item: any) => ({
    ...item,
    key: t(`common.${item.key}`),
  }))

  return (
    <Box paddingY={2} display="flex" flexDirection="column" gap={4}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          {t('common.Monthly average exposure in kWh')}
        </Typography>
      </Box>
      <Box>
        <ChartContainer ref={ref}>
          {!data || data.length === 0 ? (
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                {t('common.no_data_available')}
              </Typography>
            </Box>
          ) : (
            <BarChart width={width - 10} height={300} data={dataTranslate}>
              <XAxis dataKey="key" />
              <YAxis />
              <Tooltip content={CustomTooltipExposure} />
              <Bar
                dataKey="average_exposure"
                barSize={40}
                fill={theme.palette.primary.main || '#2FACE6'}
                opacity={1}
              />
            </BarChart>
          )}
        </ChartContainer>
      </Box>
    </Box>
  )
}

export default MonthlyAverageExposure
