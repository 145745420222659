import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { InfoBox, toReadableNumber, useI18n } from '@popety_io/popety-io-lib'

import { TitleBox } from '../../../../../components/BuildingCardDetails/BuildingCardDetails.style'
import CollapseList from '../../../../LandDetail/Summary/CollapseList'

const LandSummaryInformations = ({ land }: any) => {
  const { t } = useI18n()

  const data = [
    { label: t('land.devScore.EGRID'), value: land?.egrid_nb },
    { label: t('land.devScore.type'), value: land?.type },
    {
      label: t('land.devScore.area'),
      value: land?.official_area ? (
        <>
          {toReadableNumber(land.official_area)} m<sup>2</sup>
        </>
      ) : (
        <Tooltip title={t('land.devScore.estimatedByPopety')}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            <span>
              {toReadableNumber(land?.area)} m<sup>2</sup>
            </span>
            <WarningIcon fontSize="small" />
          </div>
        </Tooltip>
      ),
    },
    {
      label: t('land.devScore.communeNumber'),
      value: land?.city_ofs_number,
    },
  ]

  const data2 = [
    {
      label: t('common.addresses'),
      value: (
        <CollapseList
          items={land?.addresses}
          moreKey="common.seeMoreAddresses"
        />
      ),
    },
  ]

  return (
    <Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={2}
        width="100%"
      >
        <TitleBox />
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          {/* <IconLink
            href={`/developer-map?type=land&landId=${land?.land_id}&lat=${land?.geo_center?.lat}&lon=${land?.geo_center?.lon}`}
            icon={<MapIcon />}
            text={t('common.explore')}
            sx={{ color: '#969696', paddingTop: 0.4 }}
          />
          <IconLink
            href={`/land-detail/plot?landId=${land?.land_id}`}
            icon={<HomeWorkIcon />}
            text={t('common.landDetailAnalysis')}
            sx={{ color: '#969696', paddingTop: 0.4 }}
          /> */}
        </Box>
      </Box>
      <InfoBox title={t('land.Informations')} data={data} data2={data2} />
    </Box>
  )
}

export default LandSummaryInformations
