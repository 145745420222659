import { useSelector } from 'react-redux'
import { ContentTitle, Score, useI18n } from '@popety_io/popety-io-lib'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useLocation } from 'react-router-dom'

import { livabilityDistance } from '../../../config'
import LandScore from '../Summary/LandScore'
import {
  landSelector,
  landsSelector,
  poisNumberSelector,
} from '../redux/summary'
import {
  landsSelector as buildingLandsSelector,
  poisNumberSelector as buildingPoisNumberSelector,
} from '../../BuildingDetails/redux/summary'

const LivabilityHeader = ({ type = 'land' }: any) => {
  const { t } = useI18n()
  const land: any = useSelector(landSelector)
  const landPoisNumber = useSelector(poisNumberSelector)
  const buildingPoisNumber = useSelector(buildingPoisNumberSelector)
  const poisNumber = type === 'building' ? buildingPoisNumber : landPoisNumber
  const landLands = useSelector(landsSelector)
  const buildingLands = useSelector(buildingLandsSelector)
  const lands = type === 'building' ? buildingLands : landLands

  const location = useLocation()

  const isEstimate = location.pathname.includes('estimate')

  const buildingLand = lands?.length > 0 ? lands[0] : undefined

  const data: any = [
    {
      title: <Score value={poisNumber || 0} color="#000" by100={false} />,
      subTitle: `${t('land.livability.interest')} ${livabilityDistance}m`,
      sx: { '& > h6': { fontSize: '1.846rem' } },
    },
    !isEstimate && {
      title: (
        <LandScore
          score={
            type === 'building' && buildingLand
              ? buildingLand?.livability_score
              : land?.livability_score
          }
          title={t('land.livabilityScore.livabilityScoreDesc')}
          showScoreLegend
        />
      ),
      subTitle: t('land.livabilityScore'),
    },
  ].filter(Boolean)

  return (
    <Box
      position="sticky"
      top={0}
      pt={1}
      zIndex={10}
      bgcolor="background.paper"
    >
      <ContentTitle
        m={1}
        data={data}
        sx={{
          justifyContent: 'space-between',
          '& > div': { maxWidth: '100px' },
        }}
      />
      <Divider />
    </Box>
  )
}

export default LivabilityHeader
