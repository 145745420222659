import { memo, Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const RightActions = lazy(() => import('../components/LandDetailsActions'))

const HeaderRight = () => (
  <Suspense>
    <Routes>
      <Route path="*" element={null} />
      <Route path="/land-detail/:lang" element={<RightActions isLand />} />
      <Route
        path="/building-detail/:lang"
        element={<RightActions isLand={false} />}
      />
    </Routes>
  </Suspense>
)

export default memo(HeaderRight)
