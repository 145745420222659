import SunlightExposureLegend from './SunlightExposureLegend'

const SunlightMapContent = () => {
  return (
    <>
      <SunlightExposureLegend />
    </>
  )
}

export default SunlightMapContent
