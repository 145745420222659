import { Provider } from 'react-redux'

import { store } from './redux'

import 'react-resizable/css/styles.css'
import './styles/GlobalStyle.css'
import '@popety_io/popety-io-lib/dist/styles/GlobalStyle.css'
import AppThemeProvider from './AppThemeProvider'

const App = () => {
  return (
    <Provider store={store}>
      <AppThemeProvider />
    </Provider>
  )
}

export default App
