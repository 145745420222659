import {
  useRouter,
  stringToColor,
  useI18n,
  HelpIcon,
  LanguageMenu,
  PopetyIcon,
} from '@popety_io/popety-io-lib'
import Box, { BoxProps } from '@mui/material/Box'
import type { Breakpoints } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

// import { PopetyIcon } from '../../icons/PopetyIcon'
// import { MobileMenu } from '../../atoms/MobileMenu'
// import useMediaQuery from '../../hooks/useMediaQuery'
// import { MenuList } from '../../atoms/MenuList'
// import { RightMenu, RightMenuProps } from '../../atoms/RightMenu'
import { MenuRoot } from './Menu.style'

export type MenuProps = {
  /**
   * Home url
   */
  homeUrl?: string
  /**
   * Mobile breakpoint
   */
  breakpoint?: Parameters<Breakpoints['up']>[0]

  logo?: string

  className?: string

  title?: string

  subTitle?: string

  photo?: string

  creationDate?: string

  /**
   * Right user menus data
   */
  //   rightMenus: RightMenuProps['data']
  /**
   * Fired on user avatar clicked (routing to user account or loging page for instance).
   */
  onAvatarClick?: BoxProps['onClick']

  onHelp?: IconButtonProps['onClick']
}

/**
 * App menu
 */
const Menu = ({
  className = '',
  title,
  onHelp,
  creationDate,
  photo,
  logo,
}: MenuProps) => {
  const { currentLanguage, setCurrentLanguage, t } = useI18n()

  const { query } = useRouter()

  const handleLangageChange = (language: string) => {
    setCurrentLanguage(language)

    const currentUrl = new URL(window.location.href)

    currentUrl.pathname = `/${language}${currentUrl.pathname.substring(3)}`

    window.history.pushState({}, '', currentUrl.toString())
  }

  return (
    <MenuRoot className={`Menu ${className}`} position="static">
      <Box
        className="Brand"
        display="flex"
        gap={0.5}
        alignItems="center"
        height={logo ? '-webkit-fill-available' : ''}
      >
        <Box
          aria-label="popety-logo"
          style={{ height: logo ? '100%' : '', padding: logo ? '0.3rem' : '' }}
        >
          {logo ? (
            <img
              alt="Logo"
              height="100%"
              src={logo}
              style={{ maxHeight: '50px' }}
            />
          ) : (
            <PopetyIcon />
          )}
        </Box>
      </Box>
      {query?.hash && title && (
        <Box display="flex" alignItems="center" gap={2}>
          <Box id="langauge-button">
            <LanguageMenu
              lang={currentLanguage as any}
              onChange={handleLangageChange}
            />
          </Box>
          <Box
            display="flex"
            gap={3}
            flexGrow={1}
            alignItems="center"
            justifyContent="flex-end"
            aria-label="right menu"
            role="navigation"
          >
            {onHelp && (
              <IconButton aria-label="support" onClick={onHelp} edge="end">
                <HelpIcon />
              </IconButton>
            )}
          </Box>
          <Avatar
            sx={{ bgcolor: stringToColor(title || '') }}
            alt={title}
            src={photo}
          >
            {title?.charAt(0)?.toUpperCase()}
          </Avatar>{' '}
          <Box
            display="flex"
            flexDirection="column"
            aria-label="right menu"
            role="navigation"
            fontFamily={(theme) => theme.typography.fontFamily}
            lineHeight={1.2}
          >
            <span
              style={{
                color: '#969696',
                fontWeight: 500,
                fontSize: '0.8rem',
              }}
            >
              {t('common.Rapport créé par')}:
            </span>
            <Box
              sx={{
                fontWeight: 500,
                fontSize: '1.2rem',
                color: (theme) => theme.palette.primary.main,
                // color: '#073722',
              }}
            >
              {title}
            </Box>
            <span
              style={{
                color: '#969696',
                fontWeight: 500,
                fontSize: '0.8rem',
              }}
            >
              {t('common.le')} {creationDate?.split('T')[0]}
            </span>
          </Box>
        </Box>
      )}
    </MenuRoot>
  )
}

export default Menu
