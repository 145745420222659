/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { format, useI18n } from '@popety_io/popety-io-lib'
import Typography from '@mui/material/Typography'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Box from '@mui/material/Box'
import WarningIcon from '@mui/icons-material/WarningOutlined'

const FaoDetailHeader = ({
  transaction,
}: {
  transaction: any
  transactionsIds: string[] | undefined
}) => {
  const { t } = useI18n()
  const regex = /^[\s,]*$/
  const addresses = transaction?.address
    ?.split(';')
    ?.filter((item: string) => !regex.test(item))
  const isMultipleLands = transaction?.lands?.length > 1

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
        paddingTop: 1,
        paddingBottom: 1,
        width: '100%',
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            width: '100%',
          }}
        >
          <Typography fontSize="1.4rem" fontWeight={500}>
            {t('common.Transaction')} {transaction?.official_id || ''}
          </Typography>
        </Box>
        {(addresses?.length ||
          transaction?.postal_codes_names?.length ||
          transaction?.postal_code_name) && (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              marginLeft: '-4px',
            }}
          >
            <LocationOnIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
                width: '20px',
                height: '20px',
              }}
            />
            {addresses?.length ? (
              <Box>
                {addresses?.map((address: string) => (
                  <Typography
                    key={address}
                    fontSize="1rem"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {address}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography
                fontSize="1rem"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {transaction?.postal_codes_names?.[0] ||
                  transaction?.postal_code_name}
              </Typography>
            )}
          </Box>
        )}
      </Box>

      {isMultipleLands && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            color="rgb(251, 140, 0)"
            sx={{ display: 'flex', whiteSpace: 'nowrap' }}
          >
            <WarningIcon fontSize="small" />
            {t('common.transactions.multiple_lands_transactions')}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          color="rgb(141, 155, 163)"
          sx={{ display: 'flex', whiteSpace: 'nowrap' }}
        >
          {t('common.publishedOn')}
          {' : '}
        </Typography>
        <span style={{ color: '#000000', marginLeft: 2 }}>
          {format(transaction?.date, 'P')}
        </span>
      </Box>
    </Box>
  )
}

export default FaoDetailHeader
