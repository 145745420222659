/* eslint-disable max-lines */
import { Box, Typography } from '@mui/material'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import { getYear } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  EVENT_HISTORY_CATEGORY,
  EventHistory,
  EventHistoryDetails,
  EventHistoryHeader,
  EventHistorySkeleton,
} from '../../../components/EventHistory'
import {
  faosSelector,
  landSelector,
  listingsSelector,
  rawTransactionsSelector,
} from '../redux/summary'

const LandEventHistory = () => {
  const { t } = useI18n()
  const { updateQuery } = useRouter()

  const [event, setEvent] = useState<any>({})
  const [checked, setChecked] = useState<any[]>([
    { key: EVENT_HISTORY_CATEGORY.FAO, checked: true },
    { key: EVENT_HISTORY_CATEGORY.TRANSACTION, checked: true },
    { key: EVENT_HISTORY_CATEGORY.RENTAL, checked: true },
    { key: EVENT_HISTORY_CATEGORY.SALE, checked: true },
  ])

  const land: any = useSelector(landSelector)
  const rawTransactions = useSelector(rawTransactionsSelector)
  const faos = useSelector(faosSelector)
  const listings = useSelector(listingsSelector)
  const transactionsAreLoading = false
  const faosAreLoading = false
  const listingsAreLoading = false

  const regionName = land?.region_name
  const countEvent = 10
  const transactions: any = regionName === 'Genève' ? rawTransactions : []
  const transactionList = useMemo(
    () => [...transactions].sort((a, b) => (a.date > b.date ? -1 : 1)),
    [transactions],
  )

  const rentListings = useMemo(() => {
    return listings?.filter((listing: any) => listing?.deal_type === 'rent')
  }, [listings])

  const purchaseListings = useMemo(() => {
    return listings?.filter((listing: any) => listing?.deal_type === 'purchase')
  }, [listings])

  const listEventHistoryData = useMemo(
    () => [
      ...transactionList.map((item: any) => ({
        type: EVENT_HISTORY_CATEGORY.TRANSACTION,
        ...item,
      })),
      ...faos.map((item: any) => ({
        type: EVENT_HISTORY_CATEGORY.FAO,
        date: item?.inquiry_start_date,
        ...item,
      })),
      ...rentListings.map((item: any) => ({
        type: EVENT_HISTORY_CATEGORY.RENTAL,
        date: item?.listing_timestamp,
        ...item,
      })),
      ...purchaseListings.map((item: any) => ({
        type: EVENT_HISTORY_CATEGORY.SALE,
        date: item?.listing_timestamp,
        ...item,
      })),
    ],
    [transactionList, faos, rentListings, purchaseListings],
  )

  const listEventHistory = useMemo(() => {
    const data = listEventHistoryData?.filter((item: any) => {
      return checked?.find((check) => check?.key === item?.type)?.checked
    })

    return data.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
  }, [checked, listEventHistoryData])

  const loading = useMemo(() => {
    return transactionsAreLoading || faosAreLoading || listingsAreLoading
  }, [transactionsAreLoading, faosAreLoading, listingsAreLoading])

  useEffect(() => {
    if (event?.date) {
      const year = new Date(event.date).getFullYear()

      updateQuery({
        historyLayer: year === getYear(new Date()) ? year - 1 : year,
      })
    } else {
      updateQuery({ historyLayer: getYear(new Date()) - 1 })
    }
  }, [event])

  const handleChange = (key: string) => {
    setChecked((prev) => {
      return prev.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            checked: !item.checked,
          }
        }

        return item
      })
    })
  }

  const handleSelectEvent = (event: any) => {
    setEvent(event)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <EventHistoryHeader
          countEvent={countEvent}
          event={event}
          handleChange={handleChange}
          loading={loading}
          checked={checked}
          regionName={regionName}
          faos={faos}
          rentListings={rentListings}
          purchaseListings={purchaseListings}
          transactionList={transactionList}
        />
        {loading && <EventHistorySkeleton />}

        <EventHistoryDetails event={event} setEvent={setEvent} />

        {!loading && (!event || Object.keys(event)?.length === 0) && (
          <EventHistory
            listEventHistory={listEventHistory}
            checked={checked}
            handleClickSeeMore={() => {}}
            event={event}
            handleSelectEvent={handleSelectEvent}
            sx={{
              display: !(event?.id && event?._id) ? '' : 'none',
              marginLeft: '-9px',
            }}
          />
        )}
        {!loading && listEventHistory && listEventHistory?.length === 0 && (
          <Typography sx={{ textAlign: 'center', marginTop: '20px' }}>
            {t('common.no_data_available')}
          </Typography>
        )}
      </Box>
    </div>
  )
}

export default LandEventHistory
