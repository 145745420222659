import { styled } from '@mui/material/styles'
import { ViewMoreLess } from '@popety_io/popety-io-lib'

export const LivabilityDescriptionRoot = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .formGroup {
    display: flex;
    flex-direction: row;
  }
`

export const TitleRoot = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 7px 16px 0px 0px;

  h3 {
    text-transform: uppercase;
  }

  .imgRoot {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .svgContent {
    display: flex;
    justify-content: center;
    padding: 6px 10px 4px 10px;
    border-radius: 6px;
  }

  .shopping {
    background-color: #ffff3b;
  }

  .transport {
    background-color: #bebada;
  }

  .wellness {
    background-color: #fb8072;
  }

  .grocery {
    background-color: #81b1d3;
  }

  .dining {
    background-color: #fdb462;
  }

  .healthcare {
    background-color: #b3de69;
  }

  .education {
    background-color: #fccde5;
  }

  .culture {
    background-color: #d9d9d9;
  }

  .amenities {
    background-color: #8dd3c7;
  }
`

export const StyledViewMoreLess = styled(ViewMoreLess)`
  background: #f9f9f9;
  padding: 10px;
  color: black;
  & .spanRoot {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  & .spanGrey {
    color: grey;
  }

  & .link {
    color: ${({ theme }) => theme.palette.primary.main || '#007bff'} !important;
    cursor: pointer;
  }
`
