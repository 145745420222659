import { Select, useI18n, useRouter } from '@popety_io/popety-io-lib'
import { Grid } from '@mui/material'
import LocationsFilter from '../../../../Estimate/components/HistoryAroundFilters/locationsFilter'

const EnergyHeatConsumptionFilters = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const options = [
    { label: t('common.classification.Garage'), value: 'Garage' },
    { label: t('common.classification.Industrial'), value: 'Industrial' },
    { label: t('common.classification.Mixed_1'), value: 'Mixed_1' },
    { label: t('common.classification.Mixed_2'), value: 'Mixed_2' },
    {
      label: t('common.classification.Not classified'),
      value: 'Not classified',
    },
    { label: t('common.classification.Offices'), value: 'Offices' },
    { label: t('common.classification.Other'), value: 'Other' },
    { label: t('common.classification.Public'), value: 'Public' },
    { label: t('common.classification.Residential'), value: 'Residential' },
    { label: t('common.classification.Retail'), value: 'Retail' },
  ]

  return (
    <Grid container display="flex" gap={2} justifyContent="flex-end">
      <LocationsFilter />
      <Grid item xs={12} sm={4} md={3}>
        <Select
          fullWidth
          options={options}
          placeholder={t('common.popety_classification')}
          value={query.energyPopetyClassification}
          onChange={(newValue: any) =>
            updateQuery({ energyPopetyClassification: newValue?.value })
          }
          sx={{
            width: '100%',
            borderColor: query.energyPopetyClassification ? '#00ace6' : 'grey',
            color: query.energyPopetyClassification ? '#00ace6' : 'grey',
          }}
          variant="outlined"
          label={t('common.popety_classification')}
        />
      </Grid>
    </Grid>
  )
}

export default EnergyHeatConsumptionFilters
