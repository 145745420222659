import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup'
import {
  LayersIcon,
  MapLegend,
  useI18n,
  useRouter,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import { useEffect } from 'react'
import {
  getIsochrone,
  transportTypeSelector,
  updateLivabilityOptions,
} from '../../../Estimate/redux'
import {
  buildingSelector,
  displayPoiSelector,
  landSelector,
} from '../../redux/summary'
import { StyledToggle, StyledToggleTime } from './TransportType.style'

const TransportType = () => {
  const { t } = useI18n()
  const dispatch = useDispatch<any>()

  const display = useSelector(displayPoiSelector)
  const transportType = useSelector(transportTypeSelector)
  const land: any = useSelector(landSelector)
  const building = useSelector(buildingSelector)
  const geoCenter = building?.geo_center || land?.geo_center

  useEffect(() => {
    if (geoCenter?.lon || geoCenter?.lat) {
      dispatch(getIsochrone({ type: transportType, ...geoCenter }))
    }
  }, [transportType, geoCenter, dispatch])

  const { updateQuery } = useRouter()

  const handleTransportTypeChange: ToggleButtonGroupProps['onChange'] = (
    e,
    value,
  ) => {
    if (value === 'driving') {
      updateQuery({ zoom: 10 })
    }

    if (value === 'cycling') {
      updateQuery({ zoom: 12 })
    }

    if (value === 'walking') {
      updateQuery({ zoom: 16 })
    }

    dispatch(updateLivabilityOptions({ transportType: value }))
  }

  const handleDisplayChange: SwitchProps['onChange'] = (e) => {
    dispatch(updateLivabilityOptions({ display: e.target.checked }))
  }

  return (
    <MapLegend
      right="16px"
      left="auto"
      id="livability-transport"
      title={t('land.livabilityScore.livabilityHeader')}
      icon={LayersIcon}
      padding="3px"
      defaultOpen
      className="print-legend"
    >
      <ToggleButtonGroup
        value={transportType}
        exclusive
        onChange={handleTransportTypeChange}
        aria-label="transport type"
        size="small"
      >
        <StyledToggle value="walking" aria-label="walking">
          {t('land.livabilityScore.walk')}
        </StyledToggle>
        <StyledToggle value="cycling" aria-label="cycling">
          {t('land.livabilityScore.bike')}
        </StyledToggle>
        <StyledToggle value="driving" aria-label="driving">
          {t('land.livabilityScore.car')}
        </StyledToggle>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        aria-label="transport type legend"
        size="small"
        sx={{ mt: 1 }}
      >
        <StyledToggleTime
          value="walking"
          aria-label="walking"
          sx={{ bgcolor: 'rgba(127, 188, 83, 0.7)' }}
          disabled
        >
          {transportType === 'walking' ? '2 min' : '5 min'}
        </StyledToggleTime>
        <StyledToggleTime
          value="cycling"
          aria-label="cycling"
          sx={{ bgcolor: 'rgba(188, 170, 82, 0.7);' }}
          disabled
        >
          {transportType === 'walking' ? '5 min' : '15 min'}
        </StyledToggleTime>
        <StyledToggleTime
          value="driving"
          aria-label="driving"
          sx={{ bgcolor: 'rgba(177, 73, 69, 0.7)' }}
          disabled
        >
          {transportType === 'walking' ? '10 min' : '30 min'}
        </StyledToggleTime>
      </ToggleButtonGroup>

      <FormControlLabel
        sx={{ p: 1 }}
        control={
          <Switch
            size="small"
            checked={display}
            onChange={handleDisplayChange}
            name="poi-display"
          />
        }
        label={t('land.livabilityScore.interest')}
      />
    </MapLegend>
  )
}

export default TransportType
