/* eslint-disable max-lines */
import { lazy, useEffect, useState } from 'react'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

import {
  SummaryIcon,
  BuildingIcon2,
  PlotIconV2,
  EnergyIcon,
  SunlightIconV2,
  AmenitiesIcon,
} from '@popety_io/popety-io-lib'

import HistoryIcon from '@mui/icons-material/History'
import { useSelector } from 'react-redux'
import { sectionsSelector } from '../../redux'

const BuildingDetailsSummary = lazy(() => import('../../Summary'))
const Configuration = lazy(
  () => import('../../Configuration/ConfigurationBuilding'),
)
const BuildingEventHistory = lazy(() => import('../../BuildingEventHistory'))
const LandSummary = lazy(() => import('../../LandSummary'))
const Energy = lazy(() => import('../../Energy'))
const Livability = lazy(() => import('../../../LandDetail/Livability'))
const LandNoise = lazy(() => import('../../../LandDetail/LandNoise'))
const Sunlight = lazy(() => import('../../../LandDetail/Sunlight'))

interface MenuItem {
  icon: JSX.Element
  key: string
  text: string
  page: JSX.Element
  show: boolean | undefined
  subMenus?: MenuItem[]
}
const getBuildingDetailsMenuData = (t = (k: string) => k) => {
  const [menu, setMenu] = useState<MenuItem[]>([])
  const sections = useSelector(sectionsSelector)

  const pages: any = [
    [
      {
        icon: <SummaryIcon />,
        key: 'Summary',
        text: t('building.summary'),
        page: <BuildingDetailsSummary />,
        show: true,
      },
      {
        icon: (
          <HistoryIcon
            viewBox="2 2 20 20"
            sx={{
              width: '30px',
              height: '30px',
            }}
          />
        ),
        key: 'history',
        text: t('common.historique'),
        page: <BuildingEventHistory />,
        show: true,
      },
      {
        icon: <BuildingIcon2 />,
        key: 'configuration',
        text: t('building.configuration'),
        page: <Configuration />,
        show: true,
      },
      {
        icon: <PlotIconV2 />,
        key: 'parcelle',
        text: t('building.info.parcelle'),
        page: <LandSummary />,
        show: true,
      },
      {
        icon: <EnergyIcon />,
        key: 'energy',
        text: t('building.livability.type.energy'),
        page: <Energy />,
        show: true,
      },
      {
        icon: <CrisisAlertIcon />,
        key: 'enviroment',
        text: t('building.environment'),
        page: <div />,
        subMenus: [
          {
            icon: <AmenitiesIcon />,
            key: 'livability',
            text: t('common.amenities'),
            page: <Livability type="building" />,
            show: true,
          },
          {
            icon: <VolumeUpIcon />,
            key: 'noise',
            text: t('common.noise'),
            page: <LandNoise type="building" />,
            show: true,
          },
          {
            icon: <SunlightIconV2 />,
            key: 'sunlight',
            text: t('building.sunlight'),
            page: <Sunlight />,
            show: true,
          },
        ],
        show: true,
      },
    ],
  ].filter(Boolean)

  useEffect(() => {
    if (sections && sections.length > 0) {
      setMenu(
        pages?.map((pageGroup: any) =>
          pageGroup.filter(
            (item: any) => item.show && sections.includes(item.key),
          ),
        )[0] || [],
      )
    }
  }, [sections])

  return menu
}

export { getBuildingDetailsMenuData }
