import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Typography } from '@mui/material'
import { format, useI18n } from '@popety_io/popety-io-lib'

// import PotentialSolar from './PotentialSolar'
import { sunlightHoursSelector } from '../redux/summary'
import {
  sunDateSelector,
  sunlightSummerHoursSelector,
  sunlightSummerTimesSelector,
  sunlightTimesSelector,
  sunlightWinterHoursSelector,
  sunlightWinterTimesSelector,
} from '../redux/sunlight'
import SunlightEstimateSection from './SunlightEstimateSection'
import SunlightTimeBox from './SunlightTimeBox'

const Sunlight = () => {
  const { t } = useI18n()
  // const { query } = useRouter()
  // const monthlyAverageExposure = useSelector(monthlyAverageExposureSelector)
  const sunlightDate = useSelector(sunDateSelector)
  const sunlightTime = useSelector(sunlightTimesSelector)
  const sunlightHours = useSelector(sunlightHoursSelector)

  const sunlightWinterTimes = useSelector(sunlightWinterTimesSelector)
  const sunlightWinterHours = useSelector(sunlightWinterHoursSelector)

  const sunlightSummerTimes = useSelector(sunlightSummerTimesSelector)
  const sunlightSummerHours = useSelector(sunlightSummerHoursSelector)

  const todayDate = new Date()
  const selectedDate = new Date(sunlightDate)

  const [isToday, setIsToday] = useState<boolean>()

  useEffect(() => {
    setIsToday(
      todayDate.getDate() === selectedDate.getDate() &&
        todayDate.getMonth() === selectedDate.getMonth() &&
        todayDate.getFullYear() === selectedDate.getFullYear(),
    )
  }, [sunlightDate])

  return (
    <Box
      pt={2}
      id="plot-sunlight"
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            marginBottom: '4px',
            textTransform: 'uppercase',
          }}
          fontSize="1.7rem"
          fontWeight={500}
        >
          {t('land.history.sunlight')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#969696',
            paddingBottom: '10px',
          }}
          fontSize="1rem"
        >
          {t('estimate.Heure d’ensoleillement estimée à cette addresse')}
        </Typography>
      </Box>
      <SunlightEstimateSection />
      <Box p={2} display="flex" flexDirection="column" gap={5}>
        <SunlightTimeBox
          sunlightTime={sunlightTime}
          sunlightHours={sunlightHours}
          title={`${
            isToday ? `${t('land.sun.today').toUpperCase()} - ` : ''
          }  ${format(sunlightDate, 'PPP')}`}
        />

        <SunlightTimeBox
          sunlightTime={sunlightWinterTimes}
          sunlightHours={sunlightWinterHours}
          title={`${t('land.sun.winter').toUpperCase()} - ${format(
            sunlightWinterTimes?.date,
            'dd MMMM',
          )}`}
        />

        <SunlightTimeBox
          sunlightTime={sunlightSummerTimes}
          sunlightHours={sunlightSummerHours}
          title={`${t('land.sun.summer').toUpperCase()} - ${format(
            sunlightSummerTimes?.date,
            'dd MMMM',
          )}`}
        />
      </Box>
      {/* {!query?.buildingId && <PotentialSolar />} */}
      {/* {monthlyAverageExposure?.length ? (
        <MonthlyAverageExposure data={monthlyAverageExposure} />
      ) : null} */}
    </Box>
  )
}

export default Sunlight
