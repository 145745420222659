/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { BaseLayout, useMediaQuery, useRouter } from '@popety_io/popety-io-lib'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  estimateErrorSelector,
  estimateLoadingSelector,
  getEstimateSummary,
} from '../pages/Estimate/redux'
import { getLandSummary } from '../pages/LandDetail/redux/summary'

import {
  buildingErrorSelector,
  buildingLoadingSelector,
  getBuildingSummary,
} from '../pages/BuildingDetails'
import {
  landingErrorSelector,
  landingLoadingSelector,
} from '../pages/LandDetail/redux/summary/summarySelector'
import { handleSideBar } from '../redux/appSlice'
import { useAppSelector } from '../redux/store'
import AppMenu from './AppMenu'
import HeaderActions from './HeaderActions'
import HeaderLeft from './HeaderLeft'
import HeaderRight from './HeaderRight'
import SideBox from './SideBox'

const Layout = ({ children }: any) => {
  const loadingEst = useSelector(estimateLoadingSelector)
  const errorEst = useSelector(estimateErrorSelector)
  const loadingLand = useSelector(landingLoadingSelector)
  const errorLand = useSelector(landingErrorSelector)
  const loadingBuilding = useSelector(buildingLoadingSelector)
  const errorBuilding = useSelector(buildingErrorSelector)

  const getPageType = () => {
    const currentUrl = window.location.href

    if (currentUrl.includes('land-detail')) return 'land'
    if (currentUrl.includes('building-detail')) return 'building'

    return 'estimate'
  }

  const { query } = useRouter()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const openSideBar: any = useAppSelector((state: any) => state.app.openSideBar)
  const dispatch = useDispatch()

  const { hash } = query
  const loading = hash
    ? (getPageType() === 'land' && loadingLand) ||
      (getPageType() === 'building' && loadingBuilding) ||
      (getPageType() === 'estimate' && loadingEst) ||
      false
    : false

  const onSideMenuOpen = (open: boolean) => {
    dispatch(handleSideBar(open))
  }

  useEffect(() => {
    if (hash) {
      if (getPageType() === 'land') {
        dispatch(getLandSummary({ hash }) as any)
      }
      if (getPageType() === 'building') {
        dispatch(getBuildingSummary({ hash }) as any)
      }
      if (getPageType() === 'estimate') {
        dispatch(getEstimateSummary({ hash }) as any)
      }
    }
  }, [hash])

  useEffect(() => {
    if (isDesktop) {
      dispatch(handleSideBar(true))
    }
  }, [dispatch, isDesktop, window.location.pathname])

  const isShowCloseSidePaneIcon = [].some((p) =>
    window.location.pathname.includes(p),
  )

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Ensure the Box takes the full height of the viewport
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <BaseLayout
          SideBox={<SideBox />}
          HeaderActions={<HeaderActions />}
          HeaderLeft={<HeaderLeft />}
          HeaderRight={<HeaderRight />}
          Menu={AppMenu}
          onSideMenuOpen={onSideMenuOpen}
          openSidebar={openSideBar}
          hideSidebarCloseIcon={!isShowCloseSidePaneIcon}
        >
          {children}
        </BaseLayout>
      )}
    </>
  )
}

export default Layout
