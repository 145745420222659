/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import Box from '@mui/material/Box'
import { ProgressIndicator, useI18n, useRouter } from '@popety_io/popety-io-lib'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import {
  LandDetailsRoot,
  LeftContainer,
  PopetyMapsScreenButtonRoot,
  RightContainer,
} from '../LandDetail/LandDetails.style'
import useGetBuildingDetailsData from './BuildingDetails.data'
import BuildingDetailsMap from './BuildingDetailsMap'
import { getBuildingDetailsMenuData } from './components/BuildingDetailsMenu'
import { buildingIdStrSelector, userSelector } from './redux'

const BuildingDetails = () => {
  const { query, updateQuery } = useRouter()
  const buildingIdStr = useSelector(buildingIdStrSelector)
  const [openSearch, setOpenSearch] = useState(!!query?.openNewAnalysis)
  const { t } = useI18n()

  const leftContainerRef = useRef<HTMLDivElement>()
  const rightContainerRef = useRef<HTMLDivElement>()

  const user = useSelector(userSelector)

  const { landId } = query
  const isHomePage = query?.tab === 'home'
  const location = useLocation()

  const screenSizeMap = JSON.parse(
    localStorage.getItem('screenSizeMap') || '{}',
  )
  const screenSizeMapBuildingDetail = screenSizeMap?.buildingDetail
  const [size, setSize] = useState(
    screenSizeMapBuildingDetail?.fullscreen || 'default',
  )
  const [width, setWidth] = useState(screenSizeMapBuildingDetail?.width || 42)
  const [parentWidth, setParentWidth] = useState(0)
  const canAnalyse = useMemo(() => {
    return (
      user?.can_exceed_max_analysis ||
      Number(user?.analysis_counter) < Number(user?.analysis_nb) ||
      (user?.listsAnalysis && user?.listsAnalysis?.includes(landId))
    )
  }, [user])

  const pages = getBuildingDetailsMenuData(t)

  const buildingChanged = query.buildingId !== buildingIdStr

  useGetBuildingDetailsData()

  const content = useMemo(() => {
    if (!query || !pages?.length) return

    if (query?.subTab) {
      return pages
        ?.find((p: any, i: number) =>
          !query?.tab
            ? i === 0
            : p?.key === query?.tab ||
              p?.subMenus?.find((s: any) => s?.key === query?.tab),
        )
        ?.subMenus?.find((s: any) => s?.key === query?.subTab)?.page
    }
    const page: any = pages
      .filter((menu: any) => menu?.show || menu?.key === 'history')
      .find((p: any, i: number) =>
        !query.tab
          ? i === 0
          : isHomePage
            ? p?.key === query.tab && location.pathname === p?.path
            : p?.key === query.tab,
      )

    if (page?.subMenus?.length && !query?.subTab) {
      updateQuery({
        subTab: page?.subMenus?.[0]?.key,
      })
    }

    return page?.page
  }, [query.tab, query?.subTab, pages])

  const fullscreen = size === 'large' || !content

  const handleClose = () => {
    setOpenSearch(false)
    updateQuery({ openNewAnalysis: undefined })
  }

  useEffect(() => {
    if (leftContainerRef.current) {
      const rect = leftContainerRef.current.getBoundingClientRect()

      setParentWidth(rect.width)
      if (screenSizeMapBuildingDetail) {
        if (
          screenSizeMapBuildingDetail?.fullscreen &&
          screenSizeMapBuildingDetail?.fullscreen === 'large'
        ) {
          setSize(screenSizeMapBuildingDetail?.fullscreen)
          setWidth(100)
        } else {
          setSize('default')
          setWidth(screenSizeMapBuildingDetail?.width || 42)
        }
      } else {
        setSize('default')
        setWidth(screenSizeMapBuildingDetail?.width || 42)
      }
    }
  }, [])

  useEffect(() => {
    setOpenSearch(!!query?.openNewAnalysis)
  }, [query?.openNewAnalysis])
  const handleToggleFullScreen = (size: string) => {
    if (size === 'fullScreen') {
      setWidth(100)
      setSize('large')
      localStorage.setItem(
        'screenSizeMap',
        JSON.stringify({
          ...screenSizeMap,
          buildingDetail: {
            ...screenSizeMap?.buildingDetail,
            fullscreen: 'large',
            width: 100,
          },
        }),
      )
    } else {
      setWidth(screenSizeMapBuildingDetail?.oldWidth || 70)
      setSize('default')
      localStorage.setItem(
        'screenSizeMap',
        JSON.stringify({
          ...screenSizeMap,
          buildingDetail: {
            ...screenSizeMap?.buildingDetail,
            fullscreen: 'default',
            width: screenSizeMapBuildingDetail?.oldWidth || 70,
          },
        }),
      )
    }
  }

  return (
    <LandDetailsRoot bgcolor="background.paper">
      <Box
        height="100%"
        display="flex"
        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
        flexDirection={{ xs: 'column-reverse', lg: 'row' }}
        ref={leftContainerRef}
      >
        {content && (
          <LeftContainer
            bgcolor="background.paper"
            width={{
              xs: '100%',
              lg: `${100 - width}%`,
            }}
            display={fullscreen ? 'none' : undefined}
            data-cy="land-detail-left-container"
          >
            <Suspense fallback={<ProgressIndicator circular />}>
              {content}
            </Suspense>
          </LeftContainer>
        )}
        <RightContainer
          width={{
            xs: '100%',
            lg: `${width}%`,
          }}
          ref={rightContainerRef}
        >
          <Box position="relative" height="100%">
            {content && (
              <PopetyMapsScreenButtonRoot>
                {fullscreen ? (
                  <FullscreenExitIcon
                    sx={{
                      fontSize: '2rem',
                    }}
                    onClick={() => handleToggleFullScreen('default')}
                  />
                ) : (
                  <FullscreenIcon
                    sx={{
                      fontSize: '2rem',
                    }}
                    onClick={() => {
                      handleToggleFullScreen('fullScreen')
                    }}
                  />
                )}
              </PopetyMapsScreenButtonRoot>
            )}
            <BuildingDetailsMap fullscreen={fullscreen} />
          </Box>
        </RightContainer>
      </Box>
    </LandDetailsRoot>
  )
}

export default BuildingDetails
