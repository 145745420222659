import { PageHeaderLeft, useI18n } from '@popety_io/popety-io-lib'

import {
  getScoreColore,
  getLandUsedIndice,
  getScoreConfidenceData,
} from '../utils'

export type LandRadarProps = {
  planDisplay?: string
  land: { land_use_plans?: any[]; [k: string]: any }
  modal?: boolean
}

const LandRadar = ({
  land,
  modal = true,
  planDisplay = 'now',
}: LandRadarProps) => {
  const { t } = useI18n()

  const bigestLandUsePlan = land.land_use_plans?.filter(
    (landUsePlan) => landUsePlan.cover_area_perc >= 0.5,
  )?.[0]

  const landIndice = getLandUsedIndice(land, bigestLandUsePlan)
  const confidenceData = getScoreConfidenceData(landIndice)

  let devScore = land.developmentScoreOveride || land.development_score

  if (planDisplay === 'future') {
    devScore =
      (land.developmentScoreOverideFuture || land.future_development_score) ??
      devScore
  }

  const devScoreColor = getScoreColore(devScore)

  const data = {
    scoreColor: devScoreColor,
    radarData: [
      {
        value: land.underExploitedScoreOveride || land.under_exploited_score,
        label: t('common.underExploited'),
      },
      {
        value: land.livability_score,
        label: t('common.livability'),
      },
      { value: land.geo_shape_score, label: t('common.geo') },
      { value: land.existing_score, label: t('common.legacy') },
    ],
    score: land?.developmentScoreOveride || land.development_score,
    bulletTitle: t('common.score.bullet.text'),
    bulletValue: confidenceData.value,
    scoreBullet: confidenceData.data,
    radarTitle: modal
      ? `${t('common.landAnalysis')} ${land.code_number}`
      : undefined,
  }

  return (
    <PageHeaderLeft {...(data as any)} mb={-2} sx={{ overflow: 'hidden' }} />
  )
}

export default LandRadar
