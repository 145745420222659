import { Map } from '@popety_io/popety-io-lib'

const tileSource = 'tileSource'
const tileLayer = 'tileLayer'

const tileSourceEnergy = 'tileSourceEnergy'
const tileLayerEnergy = 'tileLayerEnergy'

const getBuildingTilesEnergy = ({
  map,
}: {
  map: Map
  energyLayer: string
  isEnergyTab?: boolean
  buildingId?: string
}) => {
  try {
    if (map.getLayer(tileLayerEnergy)) {
      map.removeLayer(tileLayerEnergy)
    }
    if (map.getSource(tileSourceEnergy)) {
      map.removeSource(tileSourceEnergy)
    }
  } catch (error) {
    console.error(error)
  }

  // if (!isEnergyTab || energyLayer === 'solar_average_exposure') return

  // try {
  //   map.addSource(tileSourceEnergy, {
  //     type: 'vector',
  //     tiles,
  //     minzoom: 0,
  //     maxzoom: 24,
  //   })

  //   map.addLayer(layerEnergy(energyLayer) as any)
  // } catch (error) {
  //   console.error(error)
  // }
}

const getBuildingTiles = ({
  map,
}: {
  map: Map
  isSunlightTab?: boolean
  buildingId?: string
}) => {
  try {
    if (map.getSource(tileSource)) {
      map.removeLayer(tileLayer)
      map.removeSource(tileSource)
    }
  } catch (error) {
    console.error(error)
  }

  // if (!isSunlightTab) return

  // try {
  //   map.addSource(tileSource, {
  //     type: 'vector',
  //     tiles,
  //     minzoom: 0,
  //     maxzoom: 24,
  //   })

  //   map.addLayer(layer as any)
  // } catch (error) {
  //   console.error(error)
  // }
}

export { getBuildingTiles, getBuildingTilesEnergy }
